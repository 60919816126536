import { handleApiError } from "@/api/generic/ApiErrorHandler";
import { OrganizationGenericEntityApi } from "@/api/generic/OrganizationGenericEntityApi";
import { ApiConverter } from "@/api/mapping/ApiConverter";
import { ApiResponseParser } from "@/api/mapping/ApiResponseParser";
import { authHeader } from "@/composables/api/RequestHeaderGenerator";
import type { OrganizationTaskStatus } from "@/interfaces/organization/task/OrganizationTaskStatus";
import axios from "axios";

class OrganizationTaskStatusApi extends OrganizationGenericEntityApi<OrganizationTaskStatus> {
  apiIdentifier = "organization_task_status";

  getBaseUrl(args: { organizationId: string }) {
    return `/organizations/${args.organizationId}/task-statuses`;
  }

  parseEntity(entityFromApi: any) {
    return new ApiResponseParser(entityFromApi, "organization_task_status")
      .asOrganizationEntity()
      .asLangMap("title_lang_map")
      .asLangMap("description_lang_map")
      .asId("deleted_by_user_id")
      .asNumber("order")
      .build() as OrganizationTaskStatus;
  }

  async reorder(args: { organizationId: string; newOrderIds: string[] }) {
    const url = `${this.getBaseUrl({ organizationId: args.organizationId })}/reorder`;

    try {
      const headers = authHeader();
      const entitiesList = [];

      const request = ApiConverter.toRequestData(
        { organization_task_status_ids: args.newOrderIds },
        new URLSearchParams()
      );
      const response = await axios.put(url, request, { headers });

      for (const entityFromApi of response.data?.data || []) {
        const entity = this.parseEntity(entityFromApi);
        entitiesList.push(entity);
      }

      return entitiesList;
    } catch (error) {
      handleApiError(error);
    }
  }
}

export function useOrganizationTaskStatusApi() {
  return new OrganizationTaskStatusApi();
}
