export const getSearchRoute = (entity: string, id: string) => {
  const route = routesMap.find(
    (route) => route.entity === entity || route.entitySingular === entity
  );

  if (route) {
    return {
      name: route.name,
      params: {
        [route.id_slug]: id,
      },
    };
  } else {
    return "";
  }
};

export const routesMap = [
  {
    entity: "organization_reports",
    entitySingular: "organization_report",
    id_slug: "organizationReportId",
    name: "editOrganizationReport",
  },
  {
    entity: "organization_users",
    entitySingular: "organization_user",
    id_slug: "organizationUserId",
    name: "viewOrganizationUser",
  },
  {
    entity: "organization_billing_schedules",
    entitySingular: "organization_billing_schedule",
    id_slug: "organizationBillingScheduleId",
    name: "viewOrganizationBillingSchedule",
  },
  {
    entity: "organization_invoices",
    entitySingular: "organization_invoice",
    id_slug: "organizationInvoiceId",
    name: "viewOrganizationInvoice",
  },
  {
    entity: "organization_network_exchanges",
    entitySingular: "organization_network_exchange",
    id_slug: "organizationInvoiceNetworkExchangeId",
    name: "viewOrganizationInvoiceNetworkExchange",
  },
  {
    entity: "organization_receipts",
    entitySingular: "organization_receipt",
    id_slug: "organizationReceiptId",
    name: "viewOrganizationReceipt",
  },
  {
    entity: "organization_bank_accounts",
    entitySingular: "organization_bank_account",
    id_slug: "organizationBankAccountId",
    name: "viewOrganizationBankAccount",
  },
  {
    entity: "organization_bank_transactions",
    entitySingular: "organization_bank_transaction",
    id_slug: "organizationBankTransactionId",
    name: "viewOrganizationBankTransaction",
  },
  {
    entity: "organization_products",
    entitySingular: "organization_product",
    id_slug: "organizationProductId",
    name: "viewOrganizationProduct",
  },
  {
    entity: "organization_guidelines",
    entitySingular: "organization_guideline",
    id_slug: "organizationGuidelineId",
    name: "viewOrganizationGuideline",
  },
  {
    entity: "organization_tasks",
    entitySingular: "organization_task",
    id_slug: "organizationTaskId",
    name: "viewOrganizationTask",
  },
  {
    entity: "organization_projects",
    entitySingular: "organization_project",
    id_slug: "organizationProjectId",
    name: "viewOrganizationProject",
  },
  {
    entity: "organization_guidelines",
    entitySingular: "organization_guideline",
    id_slug: "organizationGuidelineId",
    name: "viewOrganizationGuideline",
  },
  {
    entity: "organization_contracts",
    entitySingular: "organization_contract",
    id_slug: "organizationContractId",
    name: "viewOrganizationContract",
  },
  {
    entity: "organization_playbooks",
    entitySingular: "organization_playbook",
    id_slug: "organizationPlaybookId",
    name: "viewOrganizationPlaybook",
  },
  {
    entity: "organization_payslips",
    entitySingular: "organization_payslip",
    id_slug: "organizationPayslipId",
    name: "editOrganizationPayslip",
  },
];
