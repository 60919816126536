import type { SelectOption } from "@/interfaces/common/SelectOption";
import { useFrontendStore } from "@/stores/Frontend";
import { format } from "date-fns";
import { computed } from "vue";

export function useDateFormatsOptions(includeSelect: boolean = true) {
  const frontend = useFrontendStore();

  const options = computed(() => {
    const result: (SelectOption & { format?: string })[] = [];

    if (includeSelect) {
      result.push({
        id: undefined,
        label: frontend.trans("general.operation.select")!,
      });
    }

    for (const dateFormat of dateFormatsOptions) {
      const newDate = new Date(1710160200000);
      let labelFormattedDate = "";

      if (dateFormat.alias === "date_format_option_iso_8601") {
        labelFormattedDate = `${format(newDate, dateFormat.format).replace(" ", "T")}Z`;
      } else {
        labelFormattedDate = format(newDate, dateFormat.format);
      }

      result.push({
        id: dateFormat.alias,
        label: `${dateFormat.name} - ${labelFormattedDate}`,
        format: dateFormat.format,
      });
    }

    return result;
  });

  return options;
}

export const getDateFormatFromId = (alias: string) => {
  const dateFormat = dateFormatsOptions.find((df) => df.alias === alias);

  return dateFormat?.format;
};

export const dateFormatsOptions = [
  {
    alias: "date_format_option_dashed_date",
    name: "Dashed Date",
    format: "yyyy-MM-dd",
  }, // 2025-02-24
  {
    alias: "date_format_option_slashed_date",
    name: "Slashed Date",
    format: "yyyy/MM/dd",
  }, // 2025/02/24
  {
    alias: "date_format_option_iso_8601",
    name: "ISO 8601",
    format: "yyyy-MM-dd HH:mm:ss",
  }, // 2025-02-24T12:34:56.789Z
  {
    alias: "date_format_option_date_time_24h",
    name: "Date & Time (24h)",
    format: "yyyy-MM-dd HH:mm:ss",
  }, // 2025-02-24 14:05:09
  {
    alias: "date_format_option_date_time_12h",
    name: "Date & Time (12h)",
    format: "yyyy-MM-dd hh:mm:ss a",
  }, // 2025-02-24 02:05:09 PM
  {
    alias: "date_format_option_date_time_us",
    name: "Date & Time (US)",
    format: "MM/dd/yyyy hh:mm a",
  }, // 02/24/2025 02:05 PM
  {
    alias: "date_format_option_date_time_eu",
    name: "Date & Time (EU)",
    format: "dd/MM/yyyy HH:mm",
  }, // 24/02/2025 14:05
  {
    alias: "date_format_option_utc",
    name: "UTC String",
    format: "dd MMM yyyy HH:mm:ss O",
  }, // Mon, 24 Feb 2025 14:05:09 GMT
  {
    alias: "date_format_option_unix_timestamp",
    name: "Unix Timestamp (Seconds)",
    format: "t",
  }, // 1740397696
  {
    alias: "date_format_option_unit_timestamp_ms",
    name: "Unix Timestamp (Milliseconds)",
    format: "T",
  }, // 1740397696789
];
