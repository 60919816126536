<script setup lang="ts">
import { useDateFormatsOptions } from "@/composables/frontend/DateFormatsOptions";
import type { SelectOption } from "@/interfaces/common/SelectOption";
import { useFrontendStore } from "@/stores/Frontend";
import { computed } from "vue";
import Select from "./Select.vue";

const emit = defineEmits(["update:modelValue"]);
const frontend = useFrontendStore();

const props = withDefaults(
  defineProps<{
    id: string;
    label?: string;
    description?: string;
    tooltip?: string;
    modelValue?: string | number | (string | number)[];
    options?: SelectOption[];
    placeholder?: string;
    includeSelect?: boolean;
    isClearable?: boolean;
    includeLocalDateFormat?: boolean;
  }>(),
  {
    options: () => [],
    includeSelect: true,
    isClearable: true,
    includeLocalDateFormat: false,
  }
);

const dateFormatsOptions = computed(() => {
  if (props.options.length != 0) {
    return props.options;
  }

  const selectOptions = [] as SelectOption[];

  if (props.includeLocalDateFormat) {
    selectOptions.push({
      id: "local",
      label: frontend.trans("general.field.date_format.local")!,
    });
  }

  selectOptions.push(...useDateFormatsOptions(props.includeSelect).value as SelectOption[]);

  return selectOptions;
});

const placeholderValue = computed(() => {
  if (!props.placeholder) return null

  return dateFormatsOptions.value.find(option => option.id === props.placeholder)?.label
});
</script>

<template>
  <Select
    :id="id"
    :label="label"
    :description="description"
    :tooltip="tooltip"
    :modelValue="modelValue"
    :isClearable="isClearable"
    @update:modelValue="emit('update:modelValue', $event)"
    :options="dateFormatsOptions"
    :placeholder="placeholderValue"
    v-bind="$attrs"
  />
</template>
