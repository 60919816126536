<script setup lang="ts">
import InfoDialog from "@/components/dialogs/InfoDialog.vue";
import Button from "@/components/elements/buttons/Button.vue";
import FormFieldTooltipContent from "@/components/elements/tooltip/FormFieldTooltipContent.vue";
import Help from "@/components/elements/tooltip/Help.vue";
import { openDialog } from "@/lib/Utils";
import { useFrontendStore } from "@/stores/Frontend";
import { computed } from "vue";

const props = withDefaults(defineProps<{
  id?: string;
  label?: string;
  description?: string;
  instructions?: string;
  showDescriptionAboveInput?: boolean;
  displayCenterText?: boolean;
  tooltip?: string;
  required: boolean;
  displayRequiredIndicator? :boolean;
  errors: string[];
  layout?: string;
  disabledWrapper?: boolean;
}>(), {
  layout: "standard",
  disabledWrapper: false,
  displayRequiredIndicator: undefined,
  showDescriptionAboveInput: false
});

const frontend = useFrontendStore();

const displayRequiredIndicator = computed(() => {
  if (props.displayRequiredIndicator !== undefined) {
    return props.displayRequiredIndicator;
  }

  return props.required;
});

const openInstructions = () => {
  if (!props.instructions) {
    return;
  }

  openDialog(InfoDialog, {
    title: `${props.label} - Instructions`,
    text: props.instructions,
  });
};
</script>

<template>
  <div
    class="form-field"
    :data-layout="layout"
    :class="{ 'has-error': errors.length != 0 }"
  >
    <div
      class="label-input-wrapper"
      :class="{ 'disabled-wrapper': disabledWrapper }"
    >
      <div v-if="label" class="label-wrapper">
        <slot name="label">
          <label :for="id">
            {{ label }}
            <span class="required" v-if="displayRequiredIndicator"> *</span>
          </label>
        </slot>

        <Help class="help" v-if="$slots.help || tooltip">
          <slot name="help">
            <FormFieldTooltipContent
              v-if="tooltip"
              :title="label"
              :content="tooltip"
            />
          </slot>
        </Help>

        <Button
          v-if="instructions"
          class="secondary instructions-button"
          @click="openInstructions"
        >
          {{ frontend.trans("general.operation.instructions") }}
        </Button>
      </div>
      <div
        v-if="showDescriptionAboveInput && description"
        class="description"
        :class="{'center-text': displayCenterText}"
      >
        <p>
          {{ description }}
        </p>
      </div>

      <div class="input-wrapper">
        <slot></slot>

        <div class="input-end">
          <span class="multi-lang-count" v-if="$slots['multi-lang-count']">
            <img src="@/assets/icons/IconWorld.png" alt="world" />
            <slot name="multi-lang-count" />
          </span>

          <slot name="search" />
        </div>
      </div>

      <div
        v-if="!showDescriptionAboveInput && description"
        class="description"
        :class="{'center-text': displayCenterText}"
      >
        <p>
          {{ description }}
        </p>
      </div>
    </div>

    <ul v-if="errors.length != 0" class="errors">
      <li v-for="error in errors" v-bind:key="error">
        {{ error }}
      </li>
    </ul>

    <slot name="extra"></slot>
  </div>
</template>

<style scoped lang="scss">
.form-field {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: var(--enlivy-spacing-xs);

  .label-input-wrapper {
    display: flex;

    &.disabled-wrapper {
      pointer-events: none;
      opacity: 0.5;
    }

    .label-wrapper {
      display: flex;
      gap: var(--enlivy-spacing-md);
      align-items: center;

      .instructions-button {
        margin-left: auto;
      }
    }
  }

  &[data-layout="standard"] {
    > .label-input-wrapper {
      flex-direction: column;
      align-items: stretch;
      gap: var(--enlivy-spacing-xs);
    }
  }

  &[data-layout="inline"] {
    > .label-input-wrapper {
       align-items : center;

       > .label-wrapper {
        margin : 0 var(--enlivy-spacing-sm) 0 0;
        flex-shrink : 0;
        // @todo hack created by Robert, at a later stage, we'll need to calculate in the entire box, the label widths, and make them universal, with some caps on size.
        min-width:80px;
      }
    }
  }

  &[data-layout="inline_reversed"] {
    > .label-input-wrapper {
       align-items : center;

       > .label-wrapper {
        margin : 0 var(--enlivy-spacing-sm) 0 0;
        flex-shrink : 0;
        // @todo hack created by Robert, at a later stage, we'll need to calculate in the entire box, the label widths, and make them universal, with some caps on size.
        min-width:80px;

        order: 2;
      }

       > .input-wrapper {
        order: 1;
       }
    }
  }

  &.white {
    :deep(input) {
      border-color: var(--enlivy-white-100-color);
      background: var(--enlivy-white-100-color);
    }
  }

  &.has-error {
    :deep(input) {
      border-color: var(--enlivy-red-100-color);
    }
  }
  :deep(.errors) {
    list-style-type: none;
    margin-right: auto;
    li {
      color: var(--enlivy-red-100-color);
    }
  }
}

label {
  @include font-bold-large();

  .required {
    color: var(--enlivy-red-100-color);
  }
}

.input-wrapper {
  display: flex;
  align-items: center;
  gap: var(--enlivy-spacing-md);
  position: relative;
  flex-wrap: wrap;

  :deep( > input ), :deep( > textarea ) {
    flex-basis: 220px;
    @media screen and (max-width: $breakpoint-mobile) {
      flex-basis: 500px;
    }
  }

}
.input-end {
  position: absolute;
  right: var(--enlivy-spacing-lg);
  display: flex;
  align-items: center;
  gap: var(--enlivy-spacing-md);
  top: 0;
  bottom: 0;
  .multi-lang-count {
    border-left: 1px solid var(--enlivy-grey-30-color);
    padding-left: var(--enlivy-spacing-md);
    align-self: stretch;
    align-items: flex-start;
    padding-top: var(--enlivy-spacing-lg);
    img {
      top: 3px;
      position: relative;
    }
  }
  @media screen and (max-width: $breakpoint-mobile) {
    bottom: auto;
    .multi-lang-count {
      border-left: none;
    }
  }
}

.center-text {
  p {
    text-align: center;
  }
}

.description {
  p {
    font-size : 16px;
  }
}

// data-interaction-state it's set by @mixin navigation-tabs-icons()
[data-interaction-state="active"] {
  .description {
    p {
      color: var(--enlivy-white-100-color);
    }
  }
}

.multi-lang-count {
  @include font-default();
  display: flex;
  align-items: center;
  gap: var(--enlivy-spacing-xs);

  img {
    width: 14px;
    height: 14px;
  }
}
</style>
