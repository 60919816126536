<script setup lang="ts">
import InputTrueFalse from "@/components/forms/fields/boolean/InputTrueFalse.vue";
import DateFormatSelect from "@/components/forms/fields/select/DateFormatSelect.vue";
import TimeZoneSelect from "@/components/forms/fields/select/TimeZoneSelect.vue";
import useClickAway from "@/composables/events/ClickAway";
import { offsetModifier, preventOverflowModifier } from "@/composables/Modifiers";
import { useFrontendStore } from "@/stores/Frontend";
import { createPopper } from "@popperjs/core";
import { onMounted, ref, watchEffect } from 'vue';
import { closeDialog } from "vue3-promise-dialog";

const props = defineProps<{
  trigger: HTMLElement;
}>();

const frontend = useFrontendStore();
const formWrapper = ref();
const popperInstance = ref();

watchEffect(() => {
  useClickAway(formWrapper, () => {
    closeDialog(false);
  });
});

onMounted(() => {
  popperInstance.value = createPopper(props.trigger, formWrapper.value, {
    modifiers: [offsetModifier([0, 6]), preventOverflowModifier()],
    placement: "bottom",
  });
});
</script>

<template>
  <div class="form-wrapper" ref="formWrapper">
    <Suspense @resolve="popperInstance?.update()">
      <div>
        <DateFormatSelect
          id="global_settings_format"
          class="w-full mb-2"
          :label="frontend.trans('general.field.date_format.label')"
          v-model="frontend.dateTimeSettings.dateFormat"
          :is-clearable="false"
          :includeSelect="false"
          :includeLocalDateFormat="true"
          @update:modelValue="frontend.updateDateTimeSettings('dateFormat', $event)"
        />
        <TimeZoneSelect
          id="global_settings_time_zone"
          class="w-full mb-2"
          :label="frontend.trans('general.field.timezone.label')"
          v-model="frontend.dateTimeSettings.timeZone"
          :is-clearable="false"
          :includeSelect="false"
          :includeDefaultTimeZones="true"
          @update:modelValue="frontend.updateDateTimeSettings('timeZone', $event)"
        />
        <InputTrueFalse
          id="global_settings_active_clock"
          :label="
            frontend.trans(
              'general.operation.set_active_clock',
            )
          "
          v-model="frontend.dateTimeSettings.activeClock"
          @update:modelValue="frontend.updateDateTimeSettings('activeClock', $event)"
        />
      </div>
    </Suspense>
  </div>
</template>

<style scoped lang="scss">
.form-wrapper {
  @include border-radius( var( --enlivy-general-border-radius ) );
  @include box();
  background: var( --enlivy-tooltip-color );
  z-index: 10020;
  padding: var( --enlivy-spacing-divider-sm);
  transition: none;
  width: 400px;
  max-width: 80%;
  margin-top: 10px !important;
  transition: opacity 0.2s ease-in-out !important;
}
</style>
