import IconBrandedBeta from "@/components/icons/branded/IconBrandedBeta.vue";
import IconBrandedBrowserPage from "@/components/icons/branded/IconBrandedBrowserPage.vue";
import IconBrandedDevelopers from "@/components/icons/branded/IconBrandedDevelopers.vue";
import IconBrandedExecutionOverview from "@/components/icons/branded/IconBrandedExecutionOverview.vue";
import IconBrandedExecutionTimeline from "@/components/icons/branded/IconBrandedExecutionTimeline.vue";
import IconBrandedExport from "@/components/icons/branded/IconBrandedExport.vue";
import IconBrandedHelpModal from "@/components/icons/branded/IconBrandedHelpModal.vue";
import IconBrandedHelpPopover from "@/components/icons/branded/IconBrandedHelpPopover.vue";
import IconBrandedHelpTooltip from "@/components/icons/branded/IconBrandedHelpTooltip.vue";
import IconBrandedSettings from "@/components/icons/branded/IconBrandedSettings.vue";
import IconContractSign from "@/components/icons/branded/IconContractSign.vue";
import IconNetworkExchangePull from "@/components/icons/branded/IconNetworkExchangePull.vue";
import IconNetworkExchangePush from "@/components/icons/branded/IconNetworkExchangePush.vue";
import IconPlaybook from "@/components/icons/branded/IconPlaybook.vue";
import IconBankTransaction from "@/components/icons/branded/entity/IconBankTransaction.vue";
import IconBankTransactionInbound from "@/components/icons/branded/entity/IconBankTransactionInbound.vue";
import IconBankTransactionOutbound from "@/components/icons/branded/entity/IconBankTransactionOutbound.vue";
import IconBankTransactionStateAll from "@/components/icons/branded/entity/IconBankTransactionStateAll.vue";
import IconBankTransactionStateBacklog from "@/components/icons/branded/entity/IconBankTransactionStateBacklog.vue";
import IconBankTransactionStateClassified from "@/components/icons/branded/entity/IconBankTransactionStateClassified.vue";
import IconBankTransactionStateConnected from "@/components/icons/branded/entity/IconBankTransactionStateConnected.vue";
import IconBankTransactionStateConnectedPartially from "@/components/icons/branded/entity/IconBankTransactionStateConnectedPartially.vue";
import IconBankTransactionStateDanger from "@/components/icons/branded/entity/IconBankTransactionStateDanger.vue";
import IconBillingSchedule from "@/components/icons/branded/entity/IconBillingSchedule.vue";
import IconBillingScheduleInbound from "@/components/icons/branded/entity/IconBillingScheduleInbound.vue";
import IconBillingScheduleOutbound from "@/components/icons/branded/entity/IconBillingScheduleOutbound.vue";
import IconContract from "@/components/icons/branded/entity/IconContract.vue";
import IconContractExternalInbound from "@/components/icons/branded/entity/IconContractExternalInbound.vue";
import IconContractExternalOutbound from "@/components/icons/branded/entity/IconContractExternalOutbound.vue";
import IconContractInternal from "@/components/icons/branded/entity/IconContractInternal.vue";
import IconContractInternalInbound from "@/components/icons/branded/entity/IconContractInternalInbound.vue";
import IconContractInternalOutbound from "@/components/icons/branded/entity/IconContractInternalOutbound.vue";
import IconInvoice from "@/components/icons/branded/entity/IconInvoice.vue";
import IconInvoiceExternal from "@/components/icons/branded/entity/IconInvoiceExternal.vue";
import IconInvoiceExternalInbound from "@/components/icons/branded/entity/IconInvoiceExternalInbound.vue";
import IconInvoiceExternalOutbound from "@/components/icons/branded/entity/IconInvoiceExternalOutbound.vue";
import IconInvoiceInternal from "@/components/icons/branded/entity/IconInvoiceInternal.vue";
import IconInvoiceInternalInbound from "@/components/icons/branded/entity/IconInvoiceInternalInbound.vue";
import IconInvoiceInternalOutbound from "@/components/icons/branded/entity/IconInvoiceInternalOutbound.vue";
import IconInvoiceNetworkExchange from "@/components/icons/branded/entity/IconInvoiceNetworkExchange.vue";
import IconOrangeContract from "@/components/icons/branded/entity/IconOrangeContract.vue";
import IconPayslip from "@/components/icons/branded/entity/IconPayslip.vue";
import IconPayslipInbound from "@/components/icons/branded/entity/IconPayslipInbound.vue";
import IconPayslipOutbound from "@/components/icons/branded/entity/IconPayslipOutbound.vue";
import IconProduct from "@/components/icons/branded/entity/IconProduct.vue";
import IconProject from "@/components/icons/branded/entity/IconProject.vue";
import IconReceipt from "@/components/icons/branded/entity/IconReceipt.vue";
import IconReceiptInbound from "@/components/icons/branded/entity/IconReceiptInbound.vue";
import IconReceiptOutbound from "@/components/icons/branded/entity/IconReceiptOutbound.vue";
import IconReport from "@/components/icons/branded/entity/IconReport.vue";
import IconTask from "@/components/icons/branded/entity/IconTask.vue";
import IconPricingFeatureLimit from "@/components/icons/branded/marketing/IconPricingFeatureLimit.vue";
import IconPricingFeatureStatusFalse from "@/components/icons/branded/marketing/IconPricingFeatureStatusFalse.vue";
import IconPricingFeatureStatusTrue from "@/components/icons/branded/marketing/IconPricingFeatureStatusTrue.vue";
import IconUpsellIndicator from "@/components/icons/branded/marketing/IconUpsellIndicator.vue";
import IconFileAlt from "@/components/icons/file/IconFileAlt.vue";
import IconFileCheck from "@/components/icons/file/IconFileCheck.vue";
import IconFileCsv from "@/components/icons/file/IconFileCsv.vue";
import IconFileDoc from "@/components/icons/file/IconFileDoc.vue";
import IconFileExport from "@/components/icons/file/IconFileExport.vue";
import IconFileJpg from "@/components/icons/file/IconFileJpg.vue";
import IconFileJson from "@/components/icons/file/IconFileJson.vue";
import IconFileMov from "@/components/icons/file/IconFileMov.vue";
import IconFileMp3 from "@/components/icons/file/IconFileMp3.vue";
import IconFileMp4 from "@/components/icons/file/IconFileMp4.vue";
import IconFilePdf from "@/components/icons/file/IconFilePdf.vue";
import IconFilePng from "@/components/icons/file/IconFilePng.vue";
import IconFileSimple from "@/components/icons/file/IconFileSimple.vue";
import IconFileText from "@/components/icons/file/IconFileText.vue";
import IconFileTxt from "@/components/icons/file/IconFileTxt.vue";
import IconFileVideo from "@/components/icons/file/IconFileVideo.vue";
import IconFileXls from "@/components/icons/file/IconFileXls.vue";
import IconFileZip from "@/components/icons/file/IconFileZip.vue";
import IconClone from "@/components/icons/general/actions/IconClone.vue";
import IconClose from "@/components/icons/general/actions/IconClose.vue";
import IconCopyClipboard from "@/components/icons/general/actions/IconCopyClipboard.vue";
import IconDrag from "@/components/icons/general/actions/IconDrag.vue";
import IconDragInline from "@/components/icons/general/actions/IconDragInline.vue";
import IconEdit from "@/components/icons/general/actions/IconEdit.vue";
import IconEye from "@/components/icons/general/actions/IconEye.vue";
import IconLogout from "@/components/icons/general/actions/IconLogout.vue";
import IconMenuToggle from "@/components/icons/general/actions/IconMenuToggle.vue";
import IconMoreVertical from "@/components/icons/general/actions/IconMoreVertical.vue";
import IconPlus from "@/components/icons/general/actions/IconPlus.vue";
import IconRocketPush from "@/components/icons/general/actions/IconRocketPush.vue";
import IconTags from "@/components/icons/general/actions/IconTags.vue";
import IconTrash from "@/components/icons/general/actions/IconTrash.vue";
import IconCheck from "@/components/icons/general/check/IconCheck.vue";
import IconCheckCircleEmpty from "@/components/icons/general/check/IconCheckCircleEmpty.vue";
import IconCheckCircleFilled from "@/components/icons/general/check/IconCheckCircleFilled.vue";
import IconArrowCircle from "@/components/icons/general/chevron/IconArrowCircle.vue";
import IconChevronDown from "@/components/icons/general/chevron/IconChevronDown.vue";
import IconChevronLeft from "@/components/icons/general/chevron/IconChevronLeft.vue";
import IconChevronRight from "@/components/icons/general/chevron/IconChevronRight.vue";
import IconChevronUp from "@/components/icons/general/chevron/IconChevronUp.vue";
import IconDropdownToggle from "@/components/icons/general/chevron/IconDropdownToggle.vue";
import IconLeftDoubleArrow from "@/components/icons/general/chevron/IconLeftDoubleArrow.vue";
import IconRightDoubleArrow from "@/components/icons/general/chevron/IconRightDoubleArrow.vue";
import IconAlertCircle from "@/components/icons/general/notice/IconAlertCircle.vue";
import IconAlertTriangle from "@/components/icons/general/notice/IconAlertTriangle.vue";
import IconInfo from "@/components/icons/general/notice/IconInfo.vue";
import IconBank from "@/components/icons/general/utils/IconBank.vue";
import IconBasket from "@/components/icons/general/utils/IconBasket.vue";
import IconBox from "@/components/icons/general/utils/IconBox.vue";
import IconBriefcase from "@/components/icons/general/utils/IconBriefcase.vue";
import IconClock from "@/components/icons/general/utils/IconClock.vue";
import IconDollarCircle from "@/components/icons/general/utils/IconDollarCircle.vue";
import IconEnlarge from "@/components/icons/general/utils/IconEnlarge.vue";
import IconEnvelope from "@/components/icons/general/utils/IconEnvelope.vue";
import IconLayout from "@/components/icons/general/utils/IconLayout.vue";
import IconMembership from "@/components/icons/general/utils/IconMembership.vue";
import IconPen from "@/components/icons/general/utils/IconPen.vue";
import IconRadiationCircle from "@/components/icons/general/utils/IconRadiationCircle.vue";
import IconSettings from "@/components/icons/general/utils/IconSettings.vue";
import IconTemplate from "@/components/icons/general/utils/IconTemplate.vue";
import IconUnit from "@/components/icons/general/utils/IconUnit.vue";
import IconUser from "@/components/icons/general/utils/IconUser.vue";
import IconUserCircle from "@/components/icons/general/utils/IconUserCircle.vue";
import IconUsers from "@/components/icons/general/utils/IconUsers.vue";
import IconWallet from "@/components/icons/general/utils/IconWallet.vue";
import { computed } from "vue";

const iconsBranded = {
  "branded-beta": IconBrandedBeta,
  "branded-developers": IconBrandedDevelopers,
  "branded-settings": IconBrandedSettings,
  "branded-export": IconBrandedExport,
  "branded-browser-page": IconBrandedBrowserPage,
  "branded-help-modal": IconBrandedHelpModal,
  "branded-help-popover": IconBrandedHelpPopover,
  "branded-help-tooltip": IconBrandedHelpTooltip,
  "branded-execution-timeline": IconBrandedExecutionTimeline,
  "branded-execution-overview": IconBrandedExecutionOverview,
  "branded-signature": IconEdit,
};

const iconsBrandedEntity = {
  "entity-bank-transaction": IconBankTransaction,
  "entity-bank-transaction-inbound": IconBankTransactionInbound,
  "entity-bank-transaction-outbound": IconBankTransactionOutbound,
  "entity-billing-schedule": IconBillingSchedule,
  "entity-billing-schedule-inbound": IconBillingScheduleInbound,
  "entity-billing-schedule-outbound": IconBillingScheduleOutbound,
  "invoice-external": IconInvoiceExternal,
  "entity-invoice": IconInvoice,
  "entity-invoice-external-inbound": IconInvoiceExternalInbound,
  "entity-invoice-external-outbound": IconInvoiceExternalOutbound,
  "invoice-internal": IconInvoiceInternal,
  "entity-invoice-internal-inbound": IconInvoiceInternalInbound,
  "entity-invoice-internal-outbound": IconInvoiceInternalOutbound,
  "entity-invoice-network-exchange": IconInvoiceNetworkExchange,
  "contract-external": IconInvoiceExternal,
  "entity-contract": IconContract,
  "entity-orange-contract": IconOrangeContract,
  "entity-contract-external-inbound": IconContractExternalInbound,
  "entity-contract-external-outbound": IconContractExternalOutbound,
  "contract-internal": IconContractInternal,
  "entity-contract-internal-inbound": IconContractInternalInbound,
  "entity-contract-internal-outbound": IconContractInternalOutbound,
  "entity-payslip": IconPayslip,
  "entity-payslip-inbound": IconPayslipInbound,
  "entity-payslip-outbound": IconPayslipOutbound,
  "entity-product": IconProduct,
  "entity-project": IconProject,
  "entity-playbook": IconPlaybook,
  "entity-guideline": IconPlaybook,
  "entity-receipt": IconReceipt,
  "entity-receipt-inbound": IconReceiptInbound,
  "entity-receipt-outbound": IconReceiptOutbound,
  "entity-report": IconReport,
  "entity-task": IconTask,
  "entity-bank-transaction-state-all": IconBankTransactionStateAll,
  "entity-bank-transaction-state-backlog": IconBankTransactionStateBacklog,
  "entity-bank-transaction-state-classified":
    IconBankTransactionStateClassified,
  "entity-bank-transaction-state-connected_partially":
    IconBankTransactionStateConnectedPartially,
  "entity-bank-transaction-state-connected": IconBankTransactionStateConnected,
  "entity-bank-transaction-state-danger": IconBankTransactionStateDanger,
  "pricing-feature-limit": IconPricingFeatureLimit,
  "pricing-feature-status-false": IconPricingFeatureStatusFalse,
  "pricing-feature-status-true": IconPricingFeatureStatusTrue,
  "upsell-indicator": IconUpsellIndicator,
};

const iconsFile = {
  "file-alt": IconFileAlt,
  "file-check": IconFileCheck,
  "file-csv": IconFileCsv,
  "file-doc": IconFileDoc,
  "file-export": IconFileExport,
  "file-jpg": IconFileJpg,
  "file-json": IconFileJson,
  "file-mov": IconFileMov,
  "file-mp3": IconFileMp3,
  "file-mp4": IconFileMp4,
  "file-pdf": IconFilePdf,
  "file-png": IconFilePng,
  "file-simple": IconFileSimple,
  "file-text": IconFileText,
  "file-txt": IconFileTxt,
  "file-video": IconFileVideo,
  "file-xls": IconFileXls,
  "file-zip": IconFileZip,
};

const iconsGeneralActions = {
  clone: IconClone,
  "copy-clipboard": IconCopyClipboard,
  drag: IconDrag,
  "drag-inline": IconDragInline,
  edit: IconEdit,
  "menu-toggle": IconMenuToggle,
  "more-vertical": IconMoreVertical,
  plus: IconPlus,
  "rocket-push": IconRocketPush,
  tags: IconTags,
  trash: IconTrash,
  eye: IconEye,
  logout: IconLogout,
  close: IconClose,
  "contract-sign": IconContractSign,
  "network-exchange-pull": IconNetworkExchangePull,
  "network-exchange-push": IconNetworkExchangePush,
};

const iconsGeneralCheck = {
  check: IconCheck,
  "check-circle-empty": IconCheckCircleEmpty,
  "check-circle-filled": IconCheckCircleFilled,
};

const iconsGeneralChevron = {
  "arrow-circle": IconArrowCircle,
  "chevron-up": IconChevronUp,
  "chevron-down": IconChevronDown,
  "chevron-left": IconChevronLeft,
  "chevron-right": IconChevronRight,
  "dropdown-toggle": IconDropdownToggle,
  "left-double-arrow": IconLeftDoubleArrow,
  "right-double-arrow": IconRightDoubleArrow,
};

const iconsGeneralNotice = {
  "alert-circle": IconAlertCircle,
  "alert-triangle": IconAlertTriangle,
  info: IconInfo,
};

const iconsGeneralUtils = {
  bank: IconBank,
  basket: IconBasket,
  box: IconBox,
  briefcase: IconBriefcase,
  clock: IconClock,
  "dollar-circle": IconDollarCircle,
  envelope: IconEnvelope,
  layout: IconLayout,
  membership: IconMembership,
  pen: IconPen,
  "radiation-circle": IconRadiationCircle,
  settings: IconSettings,
  template: IconTemplate,
  unit: IconUnit,
  user: IconUser,
  "entity-user": IconUser,
  "user-circle": IconUserCircle,
  users: IconUsers,
  wallet: IconWallet,
  enlarge: IconEnlarge,
};

export function useIcons() {
  const icons = computed(() => {
    return {
      ...iconsBranded,
      ...iconsBrandedEntity,
      ...iconsGeneralActions,
      ...iconsGeneralCheck,
      ...iconsGeneralChevron,
      ...iconsGeneralNotice,
      ...iconsGeneralUtils,
      ...iconsFile,
    } as { [key: string]: any };
  });

  return icons;
}
