import { OrganizationGenericEntityApi } from "@/api/generic/OrganizationGenericEntityApi";
import { ApiResponseParser } from "@/api/mapping/ApiResponseParser";
import { useOrganizationBillingScheduledPaymentApi } from "@/api/organization/OrganizationBillingScheduledPaymentApi";
import type { OrganizationBillingSchedule } from "@/interfaces/organization/billing-schedule/OrganizationBillingSchedule";

class OrganizationBillingScheduleApi extends OrganizationGenericEntityApi<OrganizationBillingSchedule> {
  apiIdentifier = "organization_billing_schedule";

  getBaseUrl(args: { organizationId: string }) {
    return `/organizations/${args.organizationId}/billing-schedules`;
  }

  parseEntity(entityFromApi: any) {
    if (Array.isArray(entityFromApi?.payments?.data)) {
      entityFromApi.payments = entityFromApi.payments.data.map((element: any) =>
        useOrganizationBillingScheduledPaymentApi().parseEntity(element)
      );
    }

    return new ApiResponseParser(entityFromApi, "organization_billing_schedule")
      .asOrganizationEntity()
      .asId("id")
      .asId("organization_bank_account_id")
      .asId("organization_sender_user_id")
      .asId("organization_receiver_user_id")
      .asId("organization_contract_id")
      .asNumber("total")
      .asNumber("paid_total")
      .asDate("starts_at")
      .asDate("ends_at")
      .asDate("paid_at")
      .build() as OrganizationBillingSchedule;
  }

  getDefaultInclude(): string[] {
    return ["payments"];
  }

  postDefaultInclude() {
    return ["payments"];
  }
}

export function useOrganizationBillingScheduleApi() {
  return new OrganizationBillingScheduleApi();
}
