<script setup lang="ts">
import type { SelectOption } from "@/interfaces/common/SelectOption";
import { useFrontendStore } from "@/stores/Frontend";
import { useTimeZoneStore } from "@/stores/TimeZone";
import { computed } from "vue";
import Select from "./Select.vue";

const emit = defineEmits(["update:modelValue"]);

const props = withDefaults(
  defineProps<{
    id: string;
    label?: string;
    description?: string;
    tooltip?: string;
    modelValue?: string | number | (string | number)[];
    options?: SelectOption[];
    placeholder?: string;
    includeSelect?: boolean;
    isClearable?: boolean;
    includeDefaultTimeZones?: boolean;
  }>(),
  {
    options: () => [],
    includeSelect: true,
    isClearable: true,
    includeDefaultTimeZones: false,
  }
);

const frontend = useFrontendStore();
const timeZone = useTimeZoneStore();

timeZone.fetchTimeZones();

const timeZoneSelectOptions = computed(() => {
  if (props.options.length != 0) {
    return props.options;
  }

  const selectOptions = [] as SelectOption[];

  if (
    !props.placeholder &&
    (props.modelValue == undefined ||
    (Array.isArray(props.modelValue) && props.modelValue.length == 0)) &&
    props.includeSelect
  ) {
    selectOptions.push({
      id: undefined,
      label: frontend.trans("general.operation.select")!,
    });
  }

  if (props.includeDefaultTimeZones) {
    selectOptions.push(
      { id: 'local', label: frontend.trans('general.field.timezone.local')! },
      { id: 'organization', label: frontend.trans('general.field.timezone.organization')! },
    );
  }

  if (props.options.length != 0) {
    for (const option of props.options) {
      selectOptions.push({
        ...(option.id != undefined
          ? { iconClass: `fi fi-${option.id.toLowerCase()}` }
          : {}),
        ...option,
      });
    }
  } else {
    for (const continent in timeZone.timeZones) {
      for(const zone in timeZone.timeZones[continent]) {
        selectOptions.push({
          id: zone,
          label:timeZone.timeZones[continent][zone],
        });
      }
    }
  }

  return selectOptions;
});

const placeholderValue = computed(() => {
  if (!props.placeholder) return null

  return timeZoneSelectOptions.value.find(option => option.id === props.placeholder)?.label
});
</script>

<template>
  <Select
    :id="id"
    :label="label"
    :description="description"
    :tooltip="tooltip"
    :modelValue="modelValue"
    :isClearable="isClearable"
    @update:modelValue="emit('update:modelValue', $event)"
    :options="timeZoneSelectOptions"
    :placeholder="placeholderValue"
    v-bind="$attrs"
  />
</template>
