import { handleApiError } from "@/api/generic/ApiErrorHandler";
import { authHeader } from "@/composables/api/RequestHeaderGenerator";
import type { Organization } from "@/interfaces/organization/Organization";
import { dateToApiFormat, getClientEndOfDayIso } from "@/lib/common/DateUtils";
import { setOpt } from "@/lib/Object";
import axios from "axios";
import { getAbortController } from "../generic/AbortControllerMapping";

class AnalyticsApi {
  apiIdentifier = "organization_analytics";

  getBaseUrl(args: { organizationId: string }) {
    return `/organizations/${args.organizationId}`;
  }

  async getAnalytics(args: {
    organization: Organization;
    entity: string;
    startDate: Date;
    endDate: Date;
    direction: string;
    currency: string;
    organization_sender_user_id?: string;
    organization_receiver_user_id?: string;
    payment_entity_state?: string;
  }) {
    const { organization, entity, startDate, endDate, direction, currency } =
      args;

    try {
      const response = await axios.get(
        `${this.getBaseUrl({
          organizationId: organization.id,
        })}/${entity}/analytics/summary`,
        {
          params: {
            start_date: dateToApiFormat(startDate)?.split(" ")[0] || undefined,
            end_date: getClientEndOfDayIso(endDate)?.split(" ")[0] || undefined,
            direction,
            convert_to_currency: currency,
            ...setOpt("payment_entity_state", args.payment_entity_state),
            ...setOpt(
              "organization_sender_user_id",
              args.organization_sender_user_id
            ),
            ...setOpt(
              "organization_receiver_user_id",
              args.organization_receiver_user_id
            ),
          },
          headers: authHeader(),
          signal: getAbortController(this.apiIdentifier).signal,
        }
      );

      return response.data.data;
    } catch (error) {
      handleApiError(error);
    }
  }

  async getHistoryMonthly(args: {
    organization: Organization;
    entity: string;
    startDate: Date;
    endDate: Date;
    currency: string;
    payment_entity_state?: string;
  }) {
    const { organization, entity, startDate, endDate, currency } = args;

    try {
      const response = await axios.get(
        `${this.getBaseUrl({
          organizationId: organization.id,
        })}/${entity}/analytics/history_monthly`,
        {
          params: {
            start_date: dateToApiFormat(startDate)?.split(" ")[0] || undefined,
            end_date: getClientEndOfDayIso(endDate)?.split(" ")[0] || undefined,
            convert_to_currency: currency,
            ...setOpt("payment_entity_state", args.payment_entity_state),
          },
          headers: authHeader(),
          signal: getAbortController(this.apiIdentifier).signal,
        }
      );

      return response.data.data as {
        month: string;
        inbound: number;
        outbound: number;
        total: number;
      }[];
    } catch (error) {
      handleApiError(error);
    }
  }
}

export function useAnalyticsApi() {
  return new AnalyticsApi();
}
