import type { RouteLocationNormalizedLoaded } from "vue-router";
import {
  redirectIfMissingParams,
  redirectIfNotAdmin,
} from "../RedirectBeforeEnter";

export const administrationRoutes = [
  {
    path: "/administration",
    name: "administrationDashboard",
    component: () =>
      import("@/views/administration/dashboard/AdministrationDashboard.vue"),
    beforeEnter: redirectIfNotAdmin,
    meta: {
      title: "administration.title.dashboard",
      activeNav: "administration_dashboard",
    },
  },

  {
    path: "/administration/membership-plans",
    name: "administrationMemberships",
    component: () => import("@/views/administration/membership/List.vue"),
    beforeEnter: redirectIfNotAdmin,
    meta: {
      title: "administration.membership_plans.title.list",
      activeNav: "administration_memberships_list",
      acl: "administration_membership.view",
    },
  },
  {
    path: "/administration/membership-plans/new",
    name: "createAdministrationMembership",
    component: () => import("@/views/administration/membership/Create.vue"),
    props: (route: RouteLocationNormalizedLoaded) => ({
      reuseId: route.query.reuseId,
    }),
    beforeEnter: redirectIfNotAdmin,
    meta: {
      title: "administration.membership_plans.title.add",
      activeNav: "administration_memberships_create",
      acl: "administration_membership.store",
    },
  },
  {
    path: "/administration/membership-plans/:administrationMembershipId/edit",
    name: "editAdministrationMembership",
    component: () => import("@/views/administration/membership/Edit.vue"),
    props: true,
    beforeEnter: (route: RouteLocationNormalizedLoaded) =>
      redirectIfNotAdmin().finally(() => {
        redirectIfMissingParams(route, {
          paramsToCheck: ["administrationMembershipId"],
          redirectName: "administrationMemberships",
          redirectParams: [],
        });
      }),
    meta: {
      title: "administration.membership_plans.title.edit",
      activeNav: "administration_memberships",
      acl: "administration_membership.update",
    },
  },

  {
    path: "/administration/user-account-types",
    name: "administrationUserAccountTypes",
    component: () =>
      import("@/views/administration/user-account-type/List.vue"),
    beforeEnter: redirectIfNotAdmin,
    meta: {
      title: "administration.user_account_type.title.list",
      activeNav: "administration_user_account_types_list",
      acl: "administration_user_account_type.view",
    },
  },
  {
    path: "/administration/user-account-types/new",
    name: "createAdministrationUserAccountType",
    component: () =>
      import("@/views/administration/user-account-type/Create.vue"),
    props: (route: RouteLocationNormalizedLoaded) => ({
      reuseId: route.query.reuseId,
    }),
    beforeEnter: redirectIfNotAdmin,
    meta: {
      title: "administration.user_account_type.title.add",
      activeNav: "administration_user_account_types_create",
      acl: "administration_user_account_type.store",
    },
  },
  {
    path: "/administration/user-account-types/:administrationUserAccountTypeId/edit",
    name: "editAdministrationUserAccountType",
    component: () =>
      import("@/views/administration/user-account-type/Edit.vue"),
    props: true,
    beforeEnter: (route: RouteLocationNormalizedLoaded) =>
      redirectIfNotAdmin().finally(() => {
        redirectIfMissingParams(route, {
          paramsToCheck: ["administrationUserAccountTypeId"],
          redirectName: "administrationUserAccountTypes",
          redirectParams: [],
        });
      }),
    meta: {
      title: "administration.user_account_type.title.edit",
      activeNav: "administration_user_account_types_edit",
      acl: "administration_user_account_type.update",
    },
  },

  {
    path: "/administration/user-invitation-codes",
    name: "administrationUserInvitationCodes",
    component: () =>
      import("@/views/administration/user-invitation-code/List.vue"),
    beforeEnter: redirectIfNotAdmin,
    meta: {
      title: "administration.user_invitation_code.title.list",
      activeNav: "administration_user_invitation_codes_list",
      acl: "administration_user_invitation_code.view",
    },
  },
  {
    path: "/administration/user-invitation-codes/new",
    name: "createAdministrationUserInvitationCode",
    component: () =>
      import("@/views/administration/user-invitation-code/Create.vue"),
    props: (route: RouteLocationNormalizedLoaded) => ({
      reuseId: route.query.reuseId,
    }),
    beforeEnter: redirectIfNotAdmin,
    meta: {
      title: "administration.user_invitation_code.title.add",
      activeNav: "administration_user_invitation_codes_create",
      acl: "administration_user_invitation_code.store",
    },
  },
  {
    path: "/administration/user-invitation-codes/:administrationUserInvitationCodeId",
    name: "viewAdministrationUserInvitationCode",
    component: () =>
      import("@/views/administration/user-invitation-code/View.vue"),
    props: true,
    beforeEnter: (route: RouteLocationNormalizedLoaded) =>
      redirectIfNotAdmin().finally(() => {
        redirectIfMissingParams(route, {
          paramsToCheck: ["administrationUserInvitationCodeId"],
          redirectName: "administrationUserInvitationCodes",
          redirectParams: [],
        });
      }),
    meta: {
      activeNav: "administration_user_invitation_codes_view",
      acl: "administration_user_invitation_code.view",
    },
  },
  {
    path: "/administration/user-invitation-codes/:administrationUserInvitationCodeId/edit",
    name: "editAdministrationUserInvitationCode",
    component: () =>
      import("@/views/administration/user-invitation-code/Edit.vue"),
    props: true,
    beforeEnter: (route: RouteLocationNormalizedLoaded) =>
      redirectIfNotAdmin().finally(() => {
        redirectIfMissingParams(route, {
          paramsToCheck: ["administrationUserInvitationCodeId"],
          redirectName: "administrationUserInvitationCodes",
          redirectParams: [],
        });
      }),
    meta: {
      title: "administration.user_invitation_code.title.edit",
      activeNav: "administration_user_invitation_codes_edit",
      acl: "administration_user_invitation_code.update",
    },
  },

  {
    path: "/administration/console-jobs",
    name: "runAdministrationConsoleJob",
    component: () => import("@/views/administration/console-job/Run.vue"),
    beforeEnter: redirectIfNotAdmin,
    meta: {
      title: "administration.console_job.title.run",
      activeNav: "administration_console_jobs_run",
    },
  },

  {
    path: "/administration/icons",
    name: "administrationIconDashboard",
    component: () =>
      import(
        "@/views/administration/dashboard/AdministrationIconDashboard.vue"
      ),
    beforeEnter: redirectIfNotAdmin,
    meta: {
      title: "administration.title.icon_dashboard",
      activeNav: "administration_icon_dashboard",
    },
  },

  {
    path: "/administration/animations",
    name: "administrationAnimationDashboard",
    component: () =>
      import(
        "@/views/administration/dashboard/AdministrationAnimationDashboard.vue"
      ),
    beforeEnter: redirectIfNotAdmin,
    meta: {
      title: "administration.title.animation_dashboard",
      activeNav: "administration_animation_dashboard",
    },
  },

  {
    path: "/administration/country-information",
    name: "administrationCountryInformation",
    component: () =>
      import(
        "@/views/administration/dashboard/AdministrationCountryInformation.vue"
      ),
    beforeEnter: redirectIfNotAdmin,
    meta: {
      title: "administration.title.country_information",
      activeNav: "administration_country_information",
    },
  },

  {
    path: "/administration/ai-agents",
    name: "administrationAiAgents",
    component: () => import("@/views/administration/ai-agent/List.vue"),
    beforeEnter: redirectIfNotAdmin,
    meta: {
      title: "administration.ai_agent.title.list",
      activeNav: "administration_ai_agents_list",
      acl: "administration_ai_agent.view",
    },
  },
  {
    path: "/administration/ai-agents/new",
    name: "createAdministrationAiAgent",
    component: () => import("@/views/administration/ai-agent/Create.vue"),
    props: (route: RouteLocationNormalizedLoaded) => ({
      reuseId: route.query.reuseId,
    }),
    beforeEnter: redirectIfNotAdmin,
    meta: {
      title: "administration.ai_agent.title.add",
      activeNav: "administration_ai_agents_create",
      acl: "administration_ai_agent.store",
    },
  },
  {
    path: "/administration/ai-agents/:administrationAiAgentId/edit",
    name: "editAdministrationAiAgent",
    component: () => import("@/views/administration/ai-agent/Edit.vue"),
    props: true,
    beforeEnter: (route: RouteLocationNormalizedLoaded) =>
      redirectIfNotAdmin().finally(() => {
        redirectIfMissingParams(route, {
          paramsToCheck: ["administrationAiAgentId"],
          redirectName: "administrationAiAgents",
          redirectParams: [],
        });
      }),
    meta: {
      title: "administration.ai_agent.title.edit",
      activeNav: "administration_ai_agents_edit",
      acl: "administration_ai_agent.update",
    },
  },
];
