import { AdministrationGenericEntityApi } from "@/api/generic/AdministrationGenericEntityApi";
import { ApiResponseParser } from "@/api/mapping/ApiResponseParser";
import type { AdministrationAiAgent } from "@/interfaces/administration/ai-agent/AdministrationAiAgent";

class AdministrationAiAgentApi extends AdministrationGenericEntityApi<AdministrationAiAgent> {
  apiIdentifier = "administration_ai_agent";

  getBaseUrl(): string {
    return `/ai-agents`;
  }
  parseEntity(entityFromApi: any): AdministrationAiAgent {
    return new ApiResponseParser(entityFromApi, "administration_ai_agent")
      .asId("id")
      .asDate("created_at")
      .asDate("updated_at")
      .asDate("deleted_at")
      .asLangMap("title_lang_map")
      .asLangMap("description_lang_map")
      .asId("deleted_by_user_id")
      .build() as AdministrationAiAgent;
  }
}

export function useAdministrationAiAgentApi() {
  return new AdministrationAiAgentApi();
}
