<script setup lang="ts">
import { useLayoutOptions } from "@/composables/frontend/LayoutOptions";
import { useFrontendStore } from "@/stores/Frontend";
import Select from "./Select.vue";

const frontend = useFrontendStore();

const updateLayout = (value: string) => {
  frontend.updateLayoutSettings('layout', value);
};
</script>

<template>
  <Select
    id="layout_settings_layout"
    :label="frontend.trans(
      'general.operation.set_layout',
    )"
    :modelValue="frontend.layoutSettings.layout"
    :isClearable="false"
    @update:modelValue="updateLayout"
    :options="useLayoutOptions()"
    layout="inline"
    v-bind="$attrs"
  />
</template>

<style lang="scss" scoped>
:deep(.label-input-wrapper) {
  justify-content: space-between;
}
:deep(.input-wrapper) {
  width: 50%;
}
:deep(.v-select) {
  margin-bottom: 0;
}
</style>
