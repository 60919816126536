<script setup lang="ts">
import IconEye from "@/components/icons/general/actions/IconEye.vue";

const emit = defineEmits(["show"]);
const props = withDefaults( defineProps<{
  disabled?: boolean;
  width?: string;
  height?: string;
}>(), {
  disabled: false,
  width: "30px",
  height: "30px"
});

function onClick() {
  if (props.disabled) {
    return;
  }
  emit("show");
}
</script>

<template>
  <button @click.prevent="onClick" :class="{ disabled: disabled }">
    <IconEye :color="'var(--enlivy-white-100-color)'" />
  </button>
</template>

<style scoped lang="scss">
button {
  @include border-radius( 25.5px );
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  width: v-bind( width );
  height: v-bind( height );
  background: var(--enlivy-grey-50-color);
  @include transition(all 0.3s ease-in-out);
  &:hover {
    opacity: 0.7;
  }
}
</style>
