<template>
  <div class="form-wrapper" ref="formWrapper">
    <Suspense @resolve="popperInstance?.update()">
      <div>
        <LayoutSelect class="mb-3 w-full" />
        <ThemeSelector :use-label="true" />
      </div>
    </Suspense>
  </div>
</template>

<script setup lang="ts">
import useClickAway from "@/composables/events/ClickAway";
import { offsetModifier, preventOverflowModifier } from "@/composables/Modifiers";
import { createPopper } from "@popperjs/core";
import { onMounted, ref, watchEffect } from 'vue';
import { closeDialog } from "vue3-promise-dialog";
import LayoutSelect from "../forms/fields/select/LayoutSelect.vue";
import ThemeSelector from "./ThemeSelector.vue";

const props = defineProps<{
  trigger: HTMLElement;
}>();

const formWrapper = ref();
const popperInstance = ref();

watchEffect(() => {
  useClickAway(formWrapper, () => {
    closeDialog(false);
  });
});

onMounted(() => {
  popperInstance.value = createPopper(props.trigger, formWrapper.value, {
    modifiers: [offsetModifier([0, 6]), preventOverflowModifier()],
    placement: "bottom",
  });
});
</script>

<style scoped lang="scss">
.form-wrapper {
  @include border-radius( var( --enlivy-general-border-radius ) );
  @include box();
  background: var( --enlivy-tooltip-color );
  z-index: 10020;
  padding: var( --enlivy-spacing-divider-sm);
  transition: none;
  width: 400px;
  max-width: 80%;
  margin-top: 10px !important;
  transition: opacity 0.2s ease-in-out !important;
}
</style>
