<template>
  <div
    class="date-time-settings text-nowrap flex items-center cursor-pointer"
    ref="layoutSettings"
    @click="openSettingsPopover"
  >
    <Icon
      slug="branded-browser-page"
      :width="24"
      :height="24"
      color="var(--enlivy-grey-100-color)"
    />
  </div>
</template>

<script setup lang="ts">
import LayoutSettingsPopover from "@/components/header/LayoutSettingsPopover.vue";
import Icon from "@/components/icons/Icon.vue";
import { openDialog } from "@/lib/Utils";
import { ref } from 'vue';

const layoutSettings = ref();

const openSettingsPopover = (event?: any) => {
  const target = layoutSettings.value?.$el ? layoutSettings.value?.$el : event.target;

  openDialog(LayoutSettingsPopover, {
    trigger: target,
  });
}
</script>
