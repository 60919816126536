import { useTimeZoneApi } from "@/api/TimeZoneApi";
import cityToCountryMapping from "@/data/time-zone-cities-to-countries-mapping.json";
import { useFrontendStore } from "@/stores/Frontend";
import { defineStore } from "pinia";

export const useTimeZoneStore = defineStore("timeZone", {
  state: () => ({
    cityToCountry: cityToCountryMapping as { [city: string]: string },
    timeZones: {} as {
      [continent: string]: {
        [zone: string]: string;
      };
    },
  }),
  getters: {
    getCountryByCity: (state) => {
      return (cityName?: string): string | undefined => {
        return cityName ? state.cityToCountry[cityName] : undefined;
      };
    },
  },
  actions: {
    async fetchTimeZones() {
      const apiTimeZones = (await useTimeZoneApi().fetchTimeZones()) as {
        [continent: string]: {
          [zone: string]: string;
        };
      };
      this.$patch((state) => {
        state.timeZones = apiTimeZones;
      });
    },
    findUserTimeZone() {
      if (Intl) {
        return Intl.DateTimeFormat().resolvedOptions().timeZone;
      }
      return undefined;
    },
    findUserCountryCode() {
      const frontend = useFrontendStore();
      if (Intl) {
        const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        const timeZoneArr = userTimeZone.split("/");
        const userCity = timeZoneArr[timeZoneArr.length - 1];
        const countryName = this.getCountryByCity(userCity);

        return frontend.getCountryByName(countryName)?.iso_2;
      }
      return undefined;
    },
  },
});
