import { cloneDeep } from "lodash";

export function isObject(item: any): boolean {
  return item != null && !Array.isArray(item) && typeof item == "object";
}

export function isParsableObject(item: any): boolean {
  return isObject(item) && !(item instanceof File) && !(item instanceof Date);
}

export function deepClone(input: any): any {
  return cloneDeep(input);
}

export function isEmpty(item: any): boolean {
  if (!item) {
    return true;
  }

  if (Array.isArray(item)) {
    return item.length === 0;
  }

  if (typeof item == "object") {
    if (item instanceof Date || item instanceof File) {
      return false;
    }

    return Object.keys(item).length === 0;
  }

  if (typeof item == "string") {
    return item == "";
  }

  return false;
}

/**
 * Update the properties of the initial object with the defined properties of
 * the new object.
 *
 * @param initialObj the initial object we want to update
 * @param newObj the object holding the new properties we want to assign
 */
export function updateObject(
  initialObj: { [key: string]: any },
  newObj: { [key: string]: any }
) {
  for (const key in newObj) {
    if (Object.prototype.hasOwnProperty.call(newObj, key)) {
      initialObj[key] = newObj[key];
    }
  }
}

export function clearObject(obj: { [key: string]: any }) {
  const props = Object.getOwnPropertyNames(obj);
  for (let i = 0; i < props.length; i++) {
    if (!props[i].startsWith("_")) {
      delete obj[props[i]];
    }
  }
}

export function getObjectProperty(obj: any, path: string) {
  let dotIndex = path.indexOf(".");

  while (obj && typeof obj == "object" && !(path in obj) && dotIndex != -1) {
    obj = obj[path.slice(0, dotIndex)];
    path = path.slice(dotIndex + 1);
    dotIndex = path.indexOf(".");
  }

  if (obj && typeof obj == "object" && path in obj) {
    return obj[path];
  }

  return undefined;
}

// Sets an property on an object if the condition is matched.
export function setIf(condition: boolean, key: any, element: any) {
  return condition ? { [key]: element } : {};
}

// Spread the properties of an object to another if the condition is matched.
export function spreadIf(condition: boolean, object: any) {
  return condition ? object : {};
}

// Sets an optional property on an object.
export function setOpt(key: any, element: any) {
  return element !== undefined ? { [key]: element } : {};
}

export function setIfUndefined(data: any, key: any, initValue: any) {
  if (data[key] === undefined) {
    data[key] = initValue;
  }
}

export function sortObjectKeys(obj: any) {
  if (typeof obj !== "object" || obj === null) {
    return obj;
  }

  const sortedObj: { [key: string]: any } = {};

  Object.keys(obj)
    .sort()
    .forEach((key) => {
      sortedObj[key] = sortObjectKeys(obj[key]);
    });

  return sortedObj;
}

export function findDuplicateObjectProperties(obj1: any, obj2: any): string[] {
  const result = [];
  const properties1 = Object.keys(obj1);
  const properties2 = Object.keys(obj2);

  const commonProperties = properties1.filter((property) =>
    properties2.includes(property)
  );

  for (const property of commonProperties) {
    if (obj1[property] === obj2[property]) {
      result.push(property);
    }
  }

  return result;
}

export function allPropertiesAreEmptyValues(obj: any): boolean {
  for (const key in obj) {
    if (obj[key] !== null && obj[key] !== undefined && obj[key] !== "") {
      return false;
    }

    if (typeof obj[key] === "object" && allPropertiesAreEmptyValues(obj[key])) {
      return false;
    }
  }

  return true;
}

/*
 * Deep merge two objects.
 * @param target the target object
 * @param source the source object
 * @returns the merged object
 */
export function deepMerge(target: any, source: any) {
  if (typeof target !== "object" || typeof source !== "object") {
    return target;
  }

  for (const key in source) {
    if (source[key] && typeof source[key] === "object") {
      if (Array.isArray(source[key])) {
        if (source[key].length === 0) {
          target[key] = [];
        } else {
          target[key] = target[key]
            ? target[key].map((item: any) => deepMerge(item, source[key][0]))
            : [];
        }
      } else {
        target[key] = deepMerge(target[key] || {}, source[key]);
      }
    } else {
      target[key] = source[key];
    }
  }
  return target;
}
