import { useOrganizationGuidelineApi } from "@/api/organization/OrganizationGuidelineApi";
import type { EntityIdentifier } from "@/interfaces/generic/EntityIdentifier";
import type { OrganizationGuideline } from "@/interfaces/organization/OrganizationGuideline";
import { getEntityIdentifier } from "@/lib/generic/EntityIdentifierUtils";
import { setEntityNameFields } from "@/lib/generic/StoreUtils";
import { useGenericPusherUtils } from "@/stores/generic/GenericPusherUtils";
import { OrganizationCache } from "@/stores/generic/cache/OrganizationCache";
import { OrganizationEntityStorage } from "@/stores/generic/storage/OrganizationEntityStorage";
import { OrganizationGenericActions } from "@/stores/generic/store/OrganizationGenericActions";
import { defineStore } from "pinia";

export const useOrganizationGuidelinesStore = defineStore(
  "OrganizationGuidelines",
  () => {
    const storage = new OrganizationEntityStorage<OrganizationGuideline>();
    const pageCache = new OrganizationCache<OrganizationGuideline>();

    const subscribeToSocket = (entityIdentifier: EntityIdentifier) => {
      useGenericPusherUtils<OrganizationGuideline>(
        "guideline",
        storage,
        genericActions.getById,
        pageCache.clearCache
      ).subscribeToPusher(entityIdentifier.organizationId!);
    };

    const genericActions =
      new OrganizationGenericActions<OrganizationGuideline>({
        storage: storage,
        pageCache: pageCache,
        entityApi: useOrganizationGuidelineApi(),
        enhanceEntity: enhanceEntity,
        initializationCallback: subscribeToSocket,
      });

    return {
      ...genericActions,
    };
  }
);

export function enhanceEntity(
  entity: OrganizationGuideline,
  storage: OrganizationEntityStorage<OrganizationGuideline>
) {
  const entityIdentifier = getEntityIdentifier(entity);

  setEntityNameFields(entity, () => {
    const entity = storage.get(entityIdentifier);
    const title = entity?.title;

    return entity ? title : entityIdentifier.id;
  });
}
