<script setup lang="ts">
import LoaderMiniSpinnerTimed from "@/components/loaders/LoaderMiniSpinnerTimed.vue";
import { standardLoadingDelay } from "@/lib/GlobalVariables";
import { computed, onBeforeUnmount, ref, watch } from "vue";
import InputBase from "../InputBase.vue";

const emit = defineEmits(["update:modelValue"]);
const props = withDefaults(defineProps<{
  id?: string;
  label?: string;
  description?: string;
  instructions?: string;
  tooltip?: string;
  modelValue?: boolean;
  disabled?: boolean;
  errors?: string[];
  optionsLoading?: boolean;
  layout?: string;
  showDescriptionAboveInput?: boolean;
  color?: string | null;
  isGroup?: boolean;
}>(), {
  modelValue: false,
  disabled: false,
  errors: () => [],
  optionsLoading: false,
  layout: "inline_reversed",
  showDescriptionAboveInput: false,
});

const searchLoaderRef = ref();
let searchLoaderIntervalId: ReturnType<typeof setInterval>;

watch(
  () => props.optionsLoading,
  (newOptionsLoading) => {
    if (newOptionsLoading == true) {
      searchLoaderRef.value.resetAnimation();
      // Retrigger the animation if the options had not been loaded yet.
      searchLoaderIntervalId = setInterval(() => {
        searchLoaderRef.value.resetAnimation();
      }, standardLoadingDelay);
    } else {
      clearInterval(searchLoaderIntervalId);
      searchLoaderRef.value.stopAnimation();
    }
  },
);
onBeforeUnmount(() => {
   clearInterval(searchLoaderIntervalId);
});

if (!props.isGroup) emit("update:modelValue", props.modelValue);

const updateValue = (newValue: boolean) => {
  if (props.disabled) {
    return;
  }
  emit("update:modelValue", newValue);
};

const highlightColor = computed(() => {
  return props.color ? `rgb(${props.color})` : "var(--enlivy-grey-100-color)";
});
</script>

<template>
  <InputBase
    class="true-false-field"
    :id="id"
    :label="label"
    :description="description"
    :instructions="instructions"
    :tooltip="tooltip"
    :errors="errors"
    :required="false"
    :layout="layout"
    :showDescriptionAboveInput="showDescriptionAboveInput"
  >
    <label class="switch">
      <input
        :id="id"
        type="checkbox"
        :checked="modelValue"
        :disabled="disabled"
        v-bind="$attrs"
      />
      <span
        class="slider round"
        :class="{ checked: modelValue, disabled: disabled }"
        @click.prevent="updateValue(!modelValue)"
      ></span>
    </label>

    <LoaderMiniSpinnerTimed ref="searchLoaderRef" class="search-loader" />

    <template v-if="label" #label>
      <label class="label" :for="id" @click.prevent="updateValue(!modelValue)">
        {{ label }}
      </label>
    </template>

    <!-- HACK - This template, will forward all slots -->
    <template v-for="(_, slot) of $slots" v-slot:[slot]="scope">
      <slot :name="slot" v-bind="scope"></slot>
    </template>
  </InputBase>
</template>

<style scoped lang="scss">
.true-false-field {
  :deep(.label-input-wrapper) {
    align-items: center;
    gap: var(--enlivy-spacing-md);

     .input-wrapper {
      margin-bottom :  auto;
    }

    .label-wrapper {
      max-width : calc( 100% - 80px );
    }
  }

  input {
    display: none;
  }

  label {
    @include font-bold-large();
    cursor: pointer;
  }
}

.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;

  input {
    opacity: 0;
    width: 0;
    height: 0;
  }

  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: var(--enlivy-grey-15-color);
    -webkit-transition: .4s;
    transition: .4s;

    &.round {
      border-radius: 34px;

      &:before {
        border-radius: 50%;
      }
    }

    &.disabled {
      cursor: not-allowed;
      background: var(--enlivy-grey-40-color);
    }

    &:before {
      position: absolute;
      content: "";
      height: 26px;
      width: 26px;
      left: 4px;
      bottom: 4px;
      background: v-bind(highlightColor);
      -webkit-transition: .4s;
      transition: .4s;
    }
  }
}

input:checked + .slider {
  background-color: var(--enlivy-primary-dark-color);
}

input:focus + .slider {
  box-shadow: 0 0 1px var(--enlivy-primary-dark-color);
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}
</style>
