<script setup lang="ts">
import AnimationApplicationLoading from "@/components/illustrations/AnimationApplicationLoading.vue";
import { excludedQueryReloadRoutes } from "@/router/ExcludedQueryReloadRoutes";
import { computed, nextTick, ref, watch } from "vue";
import { useRouter } from "vue-router";
import { DialogWrapper } from "vue3-promise-dialog";
import TooltipsWrapper from "./components/elements/tooltip/TooltipsWrapper.vue";
import { errorHandlerForNewApplicationVersion } from "./lib/handler/ErrorHandler";
import { useAuthStore } from "./stores/Auth";
import { useFrontendStore } from "./stores/Frontend";
import PageError from "./views/PageError.vue";

const router = useRouter();
const authStore = useAuthStore();
const frontend = useFrontendStore();

frontend.init();
if (frontend.darkMode) {
  document.body.classList.add("dark-theme");
  document
    .querySelector('meta[name="theme-color"]')
    ?.setAttribute("content", "#ffffff");
}

const internalAppRetrieveError = ref(false);
const routerChangesPage = ref(false);

router.beforeEach(() =>  routerChangesPage.value = true );
router.afterEach(() =>  routerChangesPage.value = false );

// Watch for events from AuthChannel
watch(() => authStore.authChannel.data, (newEvent: any) => {
  if (newEvent == "logout-event") {
    authStore.logoutTab();
  }
  else if (newEvent == "login-event") {
    router.back();
  }
  else if (newEvent == "refresh-event") {
    window.location.reload();
  }
})

// Error handler for dynamically imported modules
router.onError((error) => {
  if (
    error.message.includes('Failed to fetch dynamically imported module') ||
    error.message.includes("Importing a module script failed")
  ) {
    // Remove this error after 1-2 months
    console.log("App.vue Error: ", error);
    errorHandlerForNewApplicationVersion();
  }

  if (error.message == "internal_app_failed_to_retrieve_organization" || error.cause?.code == "ERR_NETWORK" || error.message == "Network Error") {
    internalAppRetrieveError.value = true;
  }
});

function onResize() {
  frontend.windowWidth = window.innerWidth;
  frontend.windowHeight = window.innerHeight;
}

onResize();
nextTick(() => {
  window.addEventListener("resize", onResize);
});
// @TODO Check if <Transition> is needed

const routerKey = computed(() => {
  const route = router.currentRoute.value

  if (route.name && excludedQueryReloadRoutes.includes((route.name).toString())) {
    return route.path;
  }

  return route.fullPath;
});
</script>

<template>
  <RouterView v-slot="{ Component }" :key="routerKey">
    <template v-if="Component">
      <Transition mode="out-in">
        <div
          v-show="true"
          :class="{'has-dot-animation': $route.meta.has_dot_animation }"
        >
          <Suspense :timeout="0">
            <!-- main content -->
            <div :class="{ 'routing-loader' : routerChangesPage }">
              <component :is="Component" :key="$route.meta.layout"></component>
            </div>

            <!-- loading state -->
            <template #fallback>
              <div class="loader-wrapper">
                <AnimationApplicationLoading />
              </div>
            </template>
          </Suspense>
        </div>
      </Transition>
    </template>

    <PageError v-else-if="internalAppRetrieveError" />

    <div v-else class="loader-wrapper">
      <AnimationApplicationLoading />
    </div>
  </RouterView>
  <DialogWrapper :transition-attrs="{ name: 'slide-fade-top' }" />
  <TooltipsWrapper />
</template>

<style lang="scss">
@import "@/assets/scss/base";
</style>

<style scoped lang="scss">
.routing-loader {
  opacity : 0.5;
  pointer-events : none;

  &::after {
    position : absolute;
    content : "";
    top : 0;
    left : 0;
    width : 100%;
    height : 100%;
    z-index: 999999;
    cursor: progress !important;
    pointer-events : auto;
  }
}

.loader-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: var(--enlivy-spacing-md);
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
