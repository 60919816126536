import * as Sentry from "@sentry/vue";
import Datepicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";
import axios from "axios";
import type Echo from "laravel-echo";
import { createPinia } from "pinia";
import Pusher from "pusher-js";
import { registerSW } from "virtual:pwa-register";
import { createApp, markRaw } from "vue";
import VueSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import Popper from "vue3-popper";
import { PromiseDialog } from "vue3-promise-dialog";
import App from "./App.vue";
import "./assets/scss/tailwind.css";
import type { User } from "./interfaces/user/User";
import {
  errorHandler,
  errorHandlerForNewApplicationVersion,
} from "./lib/handler/ErrorHandler";
import { setupEcho } from "./lib/socket/EchoUtils";
import router from "./router/Index";
import "/node_modules/flag-icons/css/flag-icons.min.css";

declare global {
  interface Window {
    userAppNamespace: string;

    Pusher: any;
    Echo: Echo<any>;
  }
}

window.userAppNamespace = crypto.randomUUID();

axios.defaults.baseURL = import.meta.env.VITE_API_URL;
document.title = import.meta.env.VITE_APP_TITLE;
const environment = import.meta.env.VITE_APP_MODE;

const app = createApp(App);

// Enable sentry only for development and production environments
if (environment.includes("development") || environment.includes("production")) {
  Sentry.init({
    app,
    dsn: "https://7455934df7da199e2fad93c6bddef65d@o1095354.ingest.sentry.io/4505728545325056",
    environment: environment,
    integrations: [
      Sentry.browserTracingIntegration({ router }),
      Sentry.replayIntegration(),
    ],
    // Performance Monitoring
    // Capture 100% of the transactions, reduce in production!
    tracesSampleRate:
      environment == "development" || environment.includes("local") ? 1.0 : 0.2,

    // Session Replay
    // This sets the sample rate at 10%. You may want to change it to 100%
    // while in development and then sample at a lower rate in production.
    replaysSessionSampleRate: 0.1,
    // If you're not already sampling the entire session, change the sample rate
    // to 100% when sampling sessions where errors occur.
    replaysOnErrorSampleRate: 1.0,
  });
}

app.config.errorHandler = errorHandler;
window.addEventListener("unhandledrejection", errorHandler);

// Once true allows the listener to be called only once AND removes the listener once invoked.
window.addEventListener("swUpdated", errorHandlerForNewApplicationVersion, {
  once: true,
});

if ("serviceWorker" in navigator) {
  registerSW({
    immediate: true,
    onNeedRefresh() {
      console.log("registerSW - on need refresh");
      errorHandlerForNewApplicationVersion();

      const user: User = JSON.parse(localStorage.getItem("user") as string);

      Sentry.captureEvent({
        message: "registerSW - onNeedRefresh",
        environment: environment,
        timestamp: Date.now(),
        user: {
          id: user.id,
          email: user.email,
        },
      });
    },

    onOfflineReady() {
      console.log("registerSW - offline ready");

      const user: User = JSON.parse(localStorage.getItem("user") as string);

      if (!user) {
        console.log("registerSW - offline user not found");

        return;
      }

      Sentry.captureEvent({
        message: "registerSW - onOfflineReady",
        environment: environment,
        timestamp: Date.now(),
        user: {
          id: user?.id,
          email: user?.email,
        },
      });
    },
    onRegisteredSW() {
      setInterval(() => {
        navigator.serviceWorker.ready.then((registration) => {
          registration.update().catch((err) => {
            console.log("SW update failed:", err);
          });
        });
      }, 60 * 1000);
    },
  });
}

// Reload page when a DOMException occurs. This is an issue from Vue 3 related to nested suspense components.
// TODO: Remove this once the issue is fixed.
window.onerror = function (error) {
  if (
    `${error}`.includes(
      "NotFoundError: Failed to execute 'insertBefore' on 'Node'"
    )
  ) {
    document.location.reload();
    return true;
  }
};

app.provide("range", function (start: number, end: number): number[] {
  const length = end - start;
  return Array.from({ length }, (_, i) => start + i);
});

const clickOutside = {
  beforeMount: (el: any, binding: any) => {
    el.clickOutsideEvent = (event: any) => {
      if (
        !(el == event.target || el.contains(event.target)) &&
        binding?.value
      ) {
        binding.value(event);
      }
    };
    document.addEventListener("click", el.clickOutsideEvent);
  },
  unmounted: (el: any) => {
    document.removeEventListener("click", el.clickOutsideEvent);
  },
};

app.directive("click-outside", clickOutside);

app.component("vue3-select", VueSelect);
app.component("Datepicker", Datepicker);
app.component("Popper", Popper);

const pinia = createPinia();
pinia.use(({ store }) => {
  store.router = markRaw(router);
});

app.use(pinia);

app.use(PromiseDialog as any);
app.use(router);

// Setup Pusher
if (
  (environment == "development" || environment.includes("local")) &&
  import.meta.env.VITE_PUSHER_LOG_TO_CONSOLE
) {
  Pusher.logToConsole = true;
}

window.Pusher = Pusher;
setupEcho();

// prettier-ignore
// @ts-ignore
window.initIntercom = (function(){const w=window;const ic=w.Intercom;if(typeof ic==="function"){ic('reattach_activator');ic('update',w.intercomSettings);}else{const d=document;const i=function(...args){i.c(args);};i.q=[];i.c=function(args){i.q.push(args);};w.Intercom=i;const l=function(){const s=d.createElement('script');s.type='text/javascript';s.async=true;s.src='https://widget.intercom.io/widget/izyd2jkw';const x=d.getElementsByTagName('script')[0];x.parentNode.insertBefore(s,x);};if(document.readyState==='complete'){l();}else if(w.attachEvent){w.attachEvent('onload',l);}else{w.addEventListener('load',l,false);}}});

app.mount("#app");
