<script setup lang="ts">
import { createTooltip, destroyTooltip } from "@/composables/Tooltip";
import type { Tooltip, TooltipOrientation } from "@/interfaces/common/Tooltip";
import { onUnmounted, ref, useSlots, watch } from "vue";

const props = withDefaults(defineProps<{
  enabled?: boolean;
  offset?: number;
  orientation?: TooltipOrientation;
}>(), {
  enabled: true,
  orientation: "right",
});

const tooltipElement = ref();
const slots = useSlots();
let tooltip = undefined as Tooltip | undefined;

if (props.enabled) {
  tooltip = createTooltip(slots.content);
}

watch(() => props.enabled, (newEnabled) => {
  hideTooltip();
  if (!tooltip && newEnabled) {
    tooltip = createTooltip(slots.content);
  }
});

function displayTooltip() {
  if (tooltip) {
    tooltip.attachRect = tooltipElement.value.getBoundingClientRect();
    tooltip.orientation = props.orientation;
    if (props.offset) {
      tooltip.offset = props.offset;
    }
    tooltip.visible = true;
  }
}

function hideTooltip() {
  if (tooltip) {
    tooltip.visible = false;
  }
}

onUnmounted(() => {
  if (tooltip) {
    destroyTooltip(tooltip);
    tooltip = undefined;
  }
});
</script>

<template>
  <div
    ref="tooltipElement"
    class="tooltip"
    v-if="enabled"
    @mouseenter="displayTooltip()"
    @mouseleave="hideTooltip()"
  >
    <slot></slot>
  </div>
  <slot v-else></slot>
</template>

<style scoped lang="scss">
.tooltip {
  --spacing-padding: var(--enlivy-spacing-md);

  display: flex;
  align-items: center;
}
</style>
