<script setup lang="ts">
import LoaderMiniSpinner from "@/components/loaders/LoaderMiniSpinner.vue";
import type { LocaleMap } from "@/interfaces/common/LocaleMap";
import type { SelectOption } from "@/interfaces/common/SelectOption";
import { deepClone, isEmpty, isObject } from "@/lib/Object";
import { useFrontendStore } from "@/stores/Frontend";
import { useOrganizationMiscStore } from "@/stores/organization/OrganizationMisc";
import { useUserStore } from "@/stores/user/User";
import { watchDeep } from "@vueuse/core";
import { isString } from "lodash";
import { computed, inject, ref } from "vue";
import InputBase from "./InputBase.vue";
import LanguageSelect from "./select/LanguageSelect.vue";

export type InputLocaleMap = LocaleMap<string> | LocaleMap<number>;

const emit = defineEmits(["update:modelValue", "update:editedLocale", "isFocused"]);
const props = withDefaults(defineProps<{
  id?: string;
  type?: string;
  label?: string;
  description?: string;
  instructions?: string;
  showDescriptionAboveInput?: boolean;
  tooltip?: string;
  placeholder?: string;
  modelValue?: string | number | InputLocaleMap;
  editedLocale?: string;
  required?: boolean;
  autocomplete?: string;
  displayRequiredIndicator? :boolean;
  parser?: any;
  errors?: string[];
  layout?: string;
  localeSelectOptions?: SelectOption[];
  maxlength?: number;
  disabled?: boolean;
  translateFromOrganizationId?: string;
}>(), {
  layout: "standard",
  type: "text",
  required: false,
  showDescriptionAboveInput: false,
  displayRequiredIndicator: undefined,
  errors: () => [],
});

const userStore = useUserStore();
const frontend = useFrontendStore();
const miscStore = useOrganizationMiscStore();

const formLocales = inject("formLocales", computed(() => undefined));
const isMultiLang = computed(() => userStore.organization.isMultilang || !isEmpty(formLocales.value));
const inputModelValue = ref(props.editedLocale == undefined
  ? props.modelValue
  : props.modelValue == null || props.modelValue == undefined ? {} : props.modelValue);
const errors = ref(props.errors);
const showTranslationLoading = ref<boolean>(false);

watchDeep(() => props.modelValue, (newModelValue) => {
  // If newModelValue is undefined continue to update the input.
  if (!props.parser) {
    inputModelValue.value = deepClone(newModelValue);
  }
  else {
    prepareInputForEdit(newModelValue);
  }
});
watchDeep(() => props.errors, (newErrorsArray) => {
  if (newErrorsArray) {
    errors.value = newErrorsArray;
  }
});

function prepareInputForEdit(
  newModelValue: string | number | InputLocaleMap | undefined
) {
  // Parse the input and then update the parent if the input contains
  // unallowed characters

  if (!isLangMap.value) {
    const parsedNewValue = String(props.parser.parse(newModelValue));

    if (parsedNewValue != inputModelValue.value) {
      inputModelValue.value = parsedNewValue;
      emit("update:modelValue", parsedNewValue);
    }
  }
  // modelValue is of type InputLocaleMap
  else {
    if (!props.editedLocale) {
      return;
    }

    const currentMap = (newModelValue || {}) as InputLocaleMap;
    if ( currentMap[props.editedLocale] == undefined) {
      delete currentMap[props.editedLocale];
      inputModelValue.value = currentMap;
      emit("update:modelValue", currentMap);
      return;
    }

    const parsedNewValue =
      String(props.parser.parse(currentMap[props.editedLocale]));

    if (
      parsedNewValue !=
      (inputModelValue.value as InputLocaleMap)[props.editedLocale]
    ) {
      currentMap[props.editedLocale] = parsedNewValue;
      inputModelValue.value = currentMap;
      emit("update:modelValue", currentMap);
    }
  }
}

const locales = computed(() => {
  const locales = formLocales.value ? formLocales.value : userStore.organization.locales;

  return isString(locales[0]) ? locales : locales.map((locale: any) => locale.id)
});
const languageOptions = computed(() => {
  if (props.localeSelectOptions) {
    return props.localeSelectOptions;
  }

  return locales.value.map(locale => ({
    id: locale,
    label: locale.toUpperCase(),
  } as SelectOption));
});

const isLangMap = computed(() => isObject(inputModelValue.value));
const filledLocalesCount = computed(() => {
  if (props.editedLocale == undefined) {
    return 1;
  }

  let count = 0;
  const currentMap = (inputModelValue.value || {}) as InputLocaleMap;
  for (const locale of locales.value) {
    if (currentMap[locale] != undefined) {
      count += 1;
    }
  }

  return count;
});

const missingLocales = computed(() => {
  const result = [];
  let userInputDetected = false;
  if (isLangMap.value && isMultiLang.value) {
    for (const locale of locales.value) {
      const currentMap = (inputModelValue.value || {}) as InputLocaleMap;
      if (currentMap[locale] == undefined || currentMap[locale] == "") {
        result.push({
          id: locale,
          label: locale.toUpperCase(),
        });
      }
      else {
        userInputDetected = true;
      }
    }
  }

  if(!userInputDetected) {
    return undefined;
  }

  return isEmpty(result) ? undefined : result;
});

const completedLocales = computed(() => {
  const currentMap = (inputModelValue.value || {}) as InputLocaleMap;
  return locales.value
    .filter((locale) => currentMap[locale] != undefined && currentMap[locale] != "")
    .map((locale) => ({
      id: locale,
      label: locale.toUpperCase(),
    }));
});

const inputValue = computed(() => {
  return isLangMap && props.editedLocale ? (inputModelValue.value as InputLocaleMap)?.[props.editedLocale] : inputModelValue.value;
});

const inputValueLength = computed(() => {
  return inputValue.value?.toString().length || 0;
});

const maxLengthCount = computed(() => {
  if (!props.maxlength) return 0;

  return inputValue.value ?
          inputValueLength.value > props.maxlength ?
          props.maxlength :
          inputValueLength.value :
        0;
});

const updateValue = (event: any) => {
  let newValue;
  // @TODO type number will be replaced by InputNumber.
  if (props.type == "number") {
    newValue = event.target.value ? parseFloat(event.target.value) : undefined;
  } else {
    newValue = event.target.value ? event.target.value : undefined;
  }

  if (props.parser && !isEmpty(newValue)) {
    checkForUnallowedCharacters(newValue);
  }

  if (!isLangMap.value) {
    if (newValue === inputModelValue.value) {
      return;
    }

    inputModelValue.value = newValue;
    emit("update:modelValue", newValue);
    return;
  }

  if (!props.editedLocale) {
    return;
  }

  const currentMap = deepClone(inputModelValue.value) || {};

  if (newValue === currentMap[props.editedLocale]) {
    return;
  }
  currentMap[props.editedLocale] = newValue;

  inputModelValue.value = currentMap;
  emit("update:modelValue", currentMap);
};

const inputFocusToggle = (val: boolean) => {
  emit("isFocused", val);
}

function checkForUnallowedCharacters(newValue: any) {
  const parsedNewValue: string  = String(props.parser.parse(newValue));

  // Display error if user entered unallowed characters
  if (newValue != parsedNewValue) {
    const index = errors.value.indexOf(
      frontend.trans(`form_builder.form.field.errors.${props.parser.parserType}`)!
    );
    if (index == -1) {
      errors.value.push(
        frontend.trans(`form_builder.form.field.errors.${props.parser.parserType}`)!
      );
    }
  } else {
    const index =
      errors.value.indexOf(
        frontend.trans(`form_builder.form.field.errors.${props.parser.parserType}`)!
      );
    if (index > -1) {
      errors.value.splice(index, 1);
    }
  }
}

const translateFromLocale = async (fromLocale: string) => {
  const organization = userStore.getActiveOrganization().value || { id: props.translateFromOrganizationId };
  if (!organization || !organization.id || !isLangMap.value || !missingLocales.value || missingLocales.value.length == 0) {
    return;
  }

  showTranslationLoading.value = true;

  const sourceText = String((inputModelValue.value as InputLocaleMap || {})[fromLocale]);
  const currentMap = deepClone(inputModelValue.value) || {};

  for (const missingLocaleOption of missingLocales.value || []) {
    const translation = await miscStore.translateText({
      organization: organization,
      text: sourceText,
      from: fromLocale,
      to: missingLocaleOption.id,
    });
    currentMap[missingLocaleOption.id] = translation?.translated_text || "";
  }

  showTranslationLoading.value = false;

  inputModelValue.value = currentMap;
  emit("update:modelValue", currentMap);
};
</script>

<template>
  <InputBase
    :id="id"
    :label="label"
    :description="description"
    :instructions="instructions"
    :tooltip="tooltip"
    :required="required"
    :displayRequiredIndicator="displayRequiredIndicator"
    :errors="errors"
    :layout="layout"
    :disabled="disabled"
    :showDescriptionAboveInput="showDescriptionAboveInput"
  >
    <LanguageSelect
      v-if="isLangMap && isMultiLang && locales.length > 1"
      class="locale-select"
      :id="`${id}_lang`"
      :modelValue="editedLocale"
      @update:modelValue="emit('update:editedLocale', $event)"
      :options="languageOptions"
    />

    <div
      v-if="maxlength"
      class="input-counter"
      :class="{ danger: inputValueLength >= maxlength }"
    >
      {{ maxLengthCount }}
      /
      {{ maxlength }}
    </div>

    <input
      :id="id"
      :maxlength="maxlength"
      :type="type"
      :autocomplete="autocomplete"
      :value="inputValue"
      :placeholder="placeholder != undefined ? placeholder : label"
      :required="
        required &&
        (!isLangMap || (inputModelValue as InputLocaleMap)?.[userStore.organization.locale] == undefined)
      "
      :disabled="disabled"
      :step="type == 'number' ? 'any' : undefined"
      @input="updateValue"
      @focus="inputFocusToggle(true)"
      @blur="inputFocusToggle(false)"
      v-bind="$attrs"
      :class="{
        'is-multi-lang': isLangMap && isMultiLang && locales.length > 1,
        'is-disabled': disabled,
      }"
    />

    <template
      v-if="isLangMap && isMultiLang && locales.length > 1"
      #multi-lang-count
    >
      {{ filledLocalesCount }} / {{ locales.length }}
    </template>

    <template v-if="missingLocales" #extra>
      <div class="input-footer">
        <ul class="errors">
          <li
            class="missing-locale"
            @click.prevent="emit('update:editedLocale', missingLocales ? missingLocales[0]?.id : undefined)"
          >
            {{ frontend.trans('general.content.missing_language') }} :
            <span
              v-for="(item, index) in missingLocales"
              v-bind:key="item.id"
              @click.prevent="(event) => {
                event.stopPropagation();
                emit('update:editedLocale', item?.id);
              }"
            >
              {{ `${item?.label}${index != missingLocales.length - 1 ? ", " : ""}` }}
            </span>
          </li>
        </ul>

        <div class="display-flex-center">
          <p>
            {{frontend.trans("general.operation.translate_automatically_from") }}
            :
            <span
              v-for="(item, index) in completedLocales"
              v-bind:key="item.id"
              class="locale-for-translate"
              @click.prevent="translateFromLocale(item.id)"
            >
              {{`${item?.label}${index != completedLocales.length - 1 ? ", " : ""}` }}
            </span>
          </p>
          <LoaderMiniSpinner
            v-if="showTranslationLoading"
            :size="16"
            :width="4"
          />
        </div>
      </div>
    </template>

    <!-- HACK - This template, will forward all slots -->
    <template v-for="(_, slot) of $slots" v-slot:[slot]="scope">
      <slot :name="slot" v-bind="scope"></slot>
    </template>
  </InputBase>
</template>

<style scoped lang="scss">
.locale-select {
  :deep(.select-wrapper .v-select .vs__dropdown-toggle .vs__selected-options) {
    gap: 0;
  }
}

.input-footer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  .missing-locale {
    cursor: pointer;
    span {
      font-weight: var(--enlivy-text-bold-font-weight);
    }
  }

  .locale-for-translate {
    font-weight: var(--enlivy-text-bold-font-weight);
    cursor: pointer;
  }
}

input {
  @include kit-form-input();
  flex: 1;
  &.is-multi-lang {
    padding-right: 85px;
    @media only screen and (max-width: $breakpoint-portrait-tablet) {
      padding-right: var(--enlivy-spacing-lg);
    }
  }
  &.is-disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }
  @media screen and (max-width: $breakpoint-mobile) {
    &.is-multi-lang {
      padding-right: var(--enlivy-spacing-lg);
    }
  }
}

.input-counter {
  @include font-default();
  position: absolute;
  right: 0;
  bottom: 100%;
  &.danger {
    color: var(--enlivy-red-100-color);
  }
}
</style>
