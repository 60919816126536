<script setup lang="ts">
import Button from "@/components/elements/buttons/Button.vue";
import ConditionalTooltip from "@/components/elements/tooltip/ConditionalTooltip.vue";
import AnimationUpdateAvailable from "@/components/illustrations/AnimationUpdateAvailable.vue";
import { useFrontendStore } from "@/stores/Frontend";
import { closeDialog } from "vue3-promise-dialog";
import BaseDialog from "./BaseDialog.vue";

defineProps<{
  title: string;
  text: string;
}>();

const frontend = useFrontendStore();
</script>

<template>
  <BaseDialog :title="title" :displayXButton="false">
    <p>
      {{ text }}
    </p>

    <div class="animation-wrapper">
      <AnimationUpdateAvailable />
    </div>

    <template #actions>
      <Button @clicked="closeDialog(true)">
        {{ frontend.trans("general.operation.reload_application") }}
      </Button>
      <ConditionalTooltip :enabled="true" orientation="top">
        <Button @clicked="closeDialog(false)" variant="danger">
          {{ frontend.trans("general.operation.do_not_reload_application.label") }}
        </Button>
        <template #content>
          <span class="tooltip-text text-center" style="width: 200px">
            {{ frontend.trans('general.operation.do_not_reload_application.disclaimer') }}
          </span>
        </template>
      </ConditionalTooltip>
    </template>
  </BaseDialog>
</template>

<style scoped lang="scss">
p {
  font-size: 16px;
  color: var(--enlivy-grey-100-color);
}

:deep(.title) {
  justify-content: center !important;
}
:deep(.actions) {
  justify-content: center;
}

.animation-wrapper {
  display: flex;
  justify-content: center;
}
</style>
