<script setup lang="ts">
import DateTimeSettingsPopover from "@/components/header/DateTimeSettingsPopover.vue";
import Icon from "@/components/icons/Icon.vue";
import { dateToDisplayFormat } from "@/lib/common/DateUtils";
import { openDialog } from "@/lib/Utils";
import { useFrontendStore } from "@/stores/Frontend";
import { useUserStore } from "@/stores/user/User";
import { computed, onUnmounted, ref, watch } from 'vue';

const currentDateTime = ref(new Date());
const frontend = useFrontendStore();
const userStore = useUserStore();

const activeClock = computed(() => frontend.dateTimeSettings?.activeClock ? true : false);
const timeZone = computed(() => {
  if (frontend.dateTimeSettings.timeZone === "local") {
    return Intl.DateTimeFormat().resolvedOptions().timeZone
  } else if (frontend.dateTimeSettings.timeZone === "organization") {
    return userStore.activeOrganization?.timezone;
  }
  return frontend.dateTimeSettings.timeZone || userStore.activeOrganization?.timezone || Intl.DateTimeFormat().resolvedOptions().timeZone
});
const dateTimeSettings = ref();
const isBigScreen = computed(() => frontend.windowWidth > frontend.deviceBreakpoints.largeDesktop);

let intervalId: ReturnType<typeof setInterval> | null;

const setClockInterval = () => {
  if (activeClock.value && isBigScreen.value && !intervalId) {
    intervalId = setInterval(() => {
      currentDateTime.value = new Date();
    }, 1000);
  }
};

const clearClockInterval = () => {
  if (intervalId !== null) {
    clearInterval(intervalId!);
    intervalId = null;
  }
};

setClockInterval();

onUnmounted(() => {
  clearClockInterval();
});

watch(activeClock, (newValue) => {
  if (newValue) {
    setClockInterval();
  } else {
    clearClockInterval();
  }
});

watch(isBigScreen, (newValue) => {
  if (newValue) {
    setClockInterval();
  } else {
    clearClockInterval();
  }
});

const openSettingsPopover = (event?: any) => {
  const target = dateTimeSettings.value?.$el ? dateTimeSettings.value?.$el : event.target;

  openDialog(DateTimeSettingsPopover, {
    trigger: target,
  });
}
</script>

<template>
  <div
    class="date-time-settings text-nowrap flex items-center cursor-pointer"
    ref="dateTimeSettings"
    @click="openSettingsPopover"
  >
    <Icon
      slug="clock"
      :width="isBigScreen ? 18 : 24"
      :height="isBigScreen ? 18 : 24"
      color="var(--enlivy-grey-50-color)"
    />
    <span class="text-theme-color hidden xl:flex items-center">
      <span v-if="activeClock" class="text-sm flex flex-col xl:flex-row  ml-3">
        <span>{{ dateToDisplayFormat(currentDateTime) }}</span>
        <span class="hidden xl:inline-block px-2">-</span>
        <span>{{ timeZone }}</span>
      </span>
    </span>
  </div>
</template>
