<script setup lang="ts">
import CreateEntityButton from "@/components/elements/buttons/CreateEntityButton.vue";
import ViewEntityButton from "@/components/elements/buttons/ViewEntityButton.vue";
import XButton from "@/components/elements/buttons/XButton.vue";
import Icon from "@/components/icons/Icon.vue";
import IconChevronDown from "@/components/icons/general/chevron/IconChevronDown.vue";
import LoaderMiniSpinnerTimed from "@/components/loaders/LoaderMiniSpinnerTimed.vue";
import type { SelectOption } from "@/interfaces/common/SelectOption";
import { standardLoadingDelay } from "@/lib/GlobalVariables";
import { withPopper } from "@/lib/Utils";
import { useFrontendStore } from "@/stores/Frontend";
import { computed, onBeforeUnmount, ref, watch } from "vue";
import InputBase from "../InputBase.vue";

const emit = defineEmits(["update:modelValue", "add", "view", "search", "empty-search"]);
defineExpose({ openDropdown });

interface Props {
  id: string;
  label?: string;
  description?: string;
  instructions?: string;
  tooltip?: string;
  modelValue?: string | number | (string | number)[];
  required?: boolean;
  disabled?: boolean;
  disabledWrapper?: boolean;
  isClearable?: boolean;
  options: SelectOption[] | undefined;
  enableBackendSearch?: boolean;
  enableFrontendSearch?: boolean;
  errors?: string[];
  showAddButton?: boolean;
  multiple?: boolean;
  optionsLoading?: boolean;
  showPreview?: boolean;
  orderBy?: string;

  layout?: string;
  backgroundColor?: string;
  arrowColor?: string;
  backgroundSelectedColor?: string;
  showDescriptionAboveInput?: boolean;

  placeholder?: string | null;

  disabledItems?: string[];
}

const props = withDefaults(defineProps<Props>(), {
  required: false,
  disabled: false,
  multiple: false,
  errors: () => [],
  options: () => [],
  showAddButton: false,
  enableBackendSearch: false,
  enableFrontendSearch: true,
  optionsLoading: false,
  isClearable: true,
  layout: "standard",
  backgroundColor: "var(--enlivy-grey-15-color)",
  arrowColor: "var(--enlivy-grey-50-color)",
  backgroundSelectedColor: "transparent",
  showDescriptionAboveInput: false,
  disabledItems: () => [],
});

const frontend = useFrontendStore();

const maxFetchable = 50;
const vue3SelectRef = ref<any>(null);
const oldSearchQuery = ref("");
const warningMessage = ref();

// This loader is used while waiting for further user input on search
const inputSearchLoaderRef = ref();
// vue-3-select loader
let selectBuiltInLoading: any = undefined;
let searchDelayTimer: NodeJS.Timeout;

onBeforeUnmount(() => {
  clearTimeout(searchDelayTimer);
});

const vueSelectOptions = computed(() => {
  const filteredOptions = props.options.filter((option) => {
    // Remove excluded options.
    if (option.excluded) {
      return false;
    }

    if (option.id !== undefined) {
      return true;
    }

    // Option is undefined, but not disabled or excluded.
    // Show if the select is optional.
    if (!props.required) {
      return true;
    }

    // Field is required so unless it has no values slected yet, hide all the undefined options.
    return (
      props.modelValue == undefined ||
      (Array.isArray(props.modelValue) && props.modelValue.length == 0)
    );
  });

  if (props.orderBy == "label_length") {
    return filteredOptions.sort((el1: SelectOption, el2: SelectOption) => {
      if (el1.label.length < el2.label.length) {
        return -1;
      } else if (el1.label.length > el2.label.length) {
        return 1;
      } else {
        // Equal length, sort alphabetically
        return el1.label.localeCompare(el2.label);
      }
    });
  }

  return filteredOptions;
});

watch(vueSelectOptions, () => {
  selectBuiltInLoading?.(false);
});

const activeOptionValue = computed(() => {
  if (!props.multiple) {
    for (const option of vueSelectOptions.value) {
      if (option.id == props.modelValue) {
        return option;
      }
    }

    return undefined;
  }

  const result = [];
  let emptyOption = undefined;
  for (const option of vueSelectOptions.value) {
    if (!option.id) {
      emptyOption = option;
    } else if (
      Array.isArray(props.modelValue) &&
      props.modelValue.includes(option.id)
    ) {
      result.push(option);
    }
  }

  if (result.length > 0) {
    return result;
  }

  return emptyOption ? [emptyOption] : [];
});

const displayClearButton = computed(() => {
  if (props.isClearable && !props.multiple) {
    if ((activeOptionValue.value as SelectOption)?.id !== undefined) {
      return true;
    }
  }
  return false;
});

const isFilterable = computed(() => {
  if (props.enableFrontendSearch) {
    return (
      !props.enableBackendSearch || vueSelectOptions.value.length < maxFetchable
    );
  }
  return false;
});

function modelValueChangedHandler(newVal: any) {
  if (props.multiple === false) {
    if (newVal === undefined || newVal === null) {
      emit("update:modelValue", undefined);
    } else if (props.modelValue != newVal.id) {
      emit("update:modelValue", newVal.id);
    }

    return;
  }

  const newValues = [];
  for (const item of newVal) {
    if (item.id) {
      newValues.push(item.id);
    }
  }

  emit("update:modelValue", newValues);
}

function searchHandler(query: string, loading: any) {
  if (!query) emit("empty-search");

  warningMessage.value = undefined;

  if (!props.enableBackendSearch || query == "") {
    selectBuiltInLoading?.(false);
    clearTimeout(searchDelayTimer);
    emit("search", undefined);
    return;
  }

  if (query.length < 3) {
    warningMessage.value = frontend.trans(
      "general.notification.search_query_with_at_least_3_characters",
    );

    selectBuiltInLoading?.(false);
    clearTimeout(searchDelayTimer);
    emit("search", undefined);
    return;
  }

  // If the query is a continuation of the previous query, then filter at frontend level
  // If the already loaded options reach the limit, that means the request has been paginated.
  // Do this only if enableFrontendSearch is true
  if (
    oldSearchQuery.value.length !== 0 &&
    query.startsWith(oldSearchQuery.value) &&
    vueSelectOptions.value.length < maxFetchable &&
    props.enableFrontendSearch
  ) {
    emit("search", undefined);
    return;
  }

  selectBuiltInLoading = loading;
  clearTimeout(searchDelayTimer);

  if (query == oldSearchQuery.value) {
    inputSearchLoaderRef.value.stopAnimation();
    return;
  }

  // Trigger the search
  inputSearchLoaderRef.value.resetAnimation();
  searchDelayTimer = setTimeout(() => {
    selectBuiltInLoading?.(true);
    oldSearchQuery.value = query;
    emit("search", query);
  }, standardLoadingDelay);
}

const selectableFilter = (option: any) => {
  if (!props.disabledItems?.length) {
    return !option.disabled;
  }

  return !props.disabledItems.includes(option.id);
};

function openDropdown() {
  if (vue3SelectRef.value) {
    vue3SelectRef.value.onSearchFocus();
  }
}
// @TODO Add :dropdown-should-open="dropdownShouldOpen" to vue3-select
//    and configure the function to return true when the element has focus,
//    but not when the element received click event on deselect button
</script>

<template>
  <InputBase
    class="select-group"
    :id="id"
    :label="label"
    :description="description"
    :instructions="instructions"
    :tooltip="tooltip"
    :required="required"
    :errors="errors"
    :layout="layout"
    :disabled-wrapper="disabledWrapper"
    :showDescriptionAboveInput="showDescriptionAboveInput"
  >
    <div class="select-wrapper">
      <ViewEntityButton
        v-if="showPreview && displayClearButton"
        @show="emit('view')"
      />

      <vue3-select
        ref="vue3SelectRef"
        :placeholder="placeholder"
        :id="id"
        :modelValue="activeOptionValue"
        :required="required"
        :options="vueSelectOptions"
        :selectable="selectableFilter"
        :multiple="multiple"
        @update:modelValue="modelValueChangedHandler"
        @search="searchHandler"
        :filterable="isFilterable"
        :clearable="displayClearButton"
        :disabled="disabled == true"
        :closeOnSelect="true"
        :calculatePosition="withPopper"
        :loading="optionsLoading"
        @click.prevent
        append-to-body
        v-bind="$attrs"
      >
        <template #open-indicator="{ attributes }">
          <LoaderMiniSpinnerTimed ref="inputSearchLoaderRef" />
          <span v-if="!disabled" v-bind="attributes" class="arrow">
            <IconChevronDown v-if="!disabled" :color="arrowColor" />
          </span>
        </template>
        <template #search="{ attributes, events }">
          <input
            class="vs__search"
            :required="
              required &&
              (Array.isArray(activeOptionValue)
                ? activeOptionValue.length == 1 &&
                  activeOptionValue[0].id == undefined
                : activeOptionValue && activeOptionValue.id === undefined)
            "
            v-bind="attributes"
            v-on="events"
          />
        </template>
        <template #selected-option-container="{ option, deselect }">
          <div
            :class="
              `vs__selected${!option.id ? ' placeholder' : ''} ` +
              `${backgroundSelectedColor != 'transparent' ? 'customScssForSelectedIem' : ''}`
            "
          >
            <slot name="selected-option" v-bind="option">
              <span
                v-if="option.icon || option.iconClass"
                :class="`icon${option.iconClass ? ` ${option.iconClass}` : ''}`"
              >
                <img
                  v-if="option.icon"
                  :src="option.icon"
                  :alt="`${option.label} icon`"
                />
              </span>
              <Icon
                v-if="option.iconComponent"
                :slug="option.iconComponent"
                :size="24"
                :width="24"
                :height="24"
                color="var(--enlivy-primary-color)"
              />
              <span v-html="option.label"></span>
            </slot>
            <XButton
              v-if="multiple"
              :size="15"
              :stroke-width="4"
              @clicked="deselect(option)"
            />
          </div>
        </template>
        <template #option="option: SelectOption">
          <slot name="option" v-bind="option">
            <div class="vue-select-option">
              <div class="flex items-center">
                <span
                  v-if="option.icon || option.iconClass"
                  :class="`icon${option.iconClass ? ` ${option.iconClass}` : ''}`"
                  class="mr-2"
                >
                  <img
                    v-if="option.icon"
                    :src="option.icon"
                    :alt="`${option.label} icon`"
                  />
                </span>
                <Icon
                  v-if="option.iconComponent"
                  :slug="option.iconComponent!"
                  :size="24"
                  :width="24"
                  :height="24"
                  class="mr-2"
                  color="var(--enlivy-primary-color)"
                />
                <span v-html="option.label"></span>
              </div>
              <p
                class="vue-select-option__description text-sm mb-1 mt-1"
                v-if="option.description"
              >
                {{ option.description }}
              </p>
            </div>
          </slot>
        </template>
      </vue3-select>

      <CreateEntityButton
        v-if="showAddButton && !displayClearButton"
        @create="emit('add')"
      />
    </div>

    <template #extra>
      <p v-if="warningMessage" class="warning-message">
        {{ warningMessage }}
      </p>

      <slot name="extra"></slot>
    </template>

    <!-- HACK - This template, will forward all slots -->
    <template v-for="(_, slot) of $slots" v-slot:[slot]="scope">
      <slot :name="slot" v-bind="scope"></slot>
    </template>
  </InputBase>
</template>

<style scoped lang="scss">
.select-wrapper {
  display: flex;
  align-items: center;
  width: 100%;
  gap: var(--enlivy-spacing-md);

  &:deep(.v-select) {
    flex: 1;
    display: flex;
    align-items: center;
    height: 50px;
    background-color: v-bind("backgroundColor");
    border: 1px solid v-bind("backgroundColor");
    border-radius: 10px;
    // prettier-ignore
    padding: var(--enlivy-spacing-md) calc(var(--enlivy-spacing-lg) - 1px);

    .vs__clear {
      fill: var(--enlivy-grey-75-color);
    }

    .arrow {
      width: 24px;
      height: 24px;
      cursor: pointer;
    }

    .vs__dropdown-toggle {
      flex: 1;
      margin: 0;
      padding: 0;
      border: 0 none;
      background-color: v-bind("backgroundColor");

      span,
      input {
        @include font-large();
        color: var(--enlivy-grey-75-color);
      }
      .vs__selected-options {
        margin: 0;
        padding: 0;
        flex-wrap: nowrap;
        gap: var(--enlivy-spacing-xs);

        .vs__selected {
          display: none;
          position: static;
          margin: 0;
          padding: 0;
          display: flex;
          gap: var(--enlivy-spacing-xs);
          border: 0 none;
          background: inherit;
          cursor: pointer;

          &:not(.placeholder) {
            &.customScssForSelectedIem {
              background: v-bind("backgroundSelectedColor");
              padding: 0 var(--enlivy-spacing-md);
            }
          }
          > span {
            overflow: hidden;
            display: -webkit-box;
            -webkit-line-clamp: 1;
            line-clamp: 1;
            -webkit-box-orient: vertical;
          }
        }
        input {
          background-color: v-bind("backgroundColor");
          margin: 0;
          padding: 0;
          &::placeholder {
            color: rgba(var(--enlivy-grey-75-color-rgb), 0.6);
          }
        }
      }
      .vs__actions {
        display: flex;
        gap: var(--enlivy-spacing-md);
      }
    }

    .vs__spinner {
      border-top: 0.9em solid var(--enlivy-primary-color);
      border-right: 0.9em solid var(--enlivy-primary-color);
      border-bottom: 0.9em solid var(--enlivy-primary-color);
    }

    &.vs--loading {
      .vs__open-indicator {
        display: none;
      }
    }
  }
}

.select-group {
  &.has-error {
    :deep(.v-select) {
      border-color: var(--enlivy-red-100-color);

      input {
        border-color: v-bind("backgroundColor");
      }
    }
  }
}

.icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 18px;
  overflow: hidden;

  img {
    max-width: 100%;
    max-height: 100%;
  }
}

.warning-message {
  color: var(--enlivy-orange-color);
}

.vue-select-option {
  &__description {
    white-space: initial;
    line-height: 1;
    color: rgba(var(--enlivy-grey-75-color-rgb), 0.7);
  }
}
</style>
