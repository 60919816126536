import { useAdministrationAiAgentApi } from "@/api/administration/AdministrationAiAgentApi";
import { getLangMapItem } from "@/composables/frontend/GetLangMapItem";
import type { AdministrationAiAgent } from "@/interfaces/administration/ai-agent/AdministrationAiAgent";
import { getEntityIdentifier } from "@/lib/generic/EntityIdentifierUtils";
import { setEntityNameFields } from "@/lib/generic/StoreUtils";
import { OrganizationCache } from "@/stores/generic/cache/OrganizationCache";
import { AdministrationEntityStorage } from "@/stores/generic/storage/AdministrationEntityStorage";
import { AdministrationGenericActions } from "@/stores/generic/store/AdministrationGenericActions";
import { defineStore } from "pinia";

export const useAdministrationAiAgentsStore = defineStore(
  "administrationAiAgent",
  () => {
    const storage = new AdministrationEntityStorage<AdministrationAiAgent>();
    const pageCache = new OrganizationCache<AdministrationAiAgent>();

    const genericActions =
      new AdministrationGenericActions<AdministrationAiAgent>({
        storage: storage,
        pageCache: pageCache,
        entityApi: useAdministrationAiAgentApi(),
        enhanceEntity: enhanceEntity,
      });

    return {
      ...genericActions,
    };
  }
);

export function enhanceEntity(
  entity: AdministrationAiAgent,
  storage: AdministrationEntityStorage<AdministrationAiAgent>
) {
  const entityIdentifier = getEntityIdentifier(entity);
  setEntityNameFields(entity, () => {
    const entity = storage.get(entityIdentifier);
    const title = getLangMapItem(entity?.title_lang_map);

    return entity
      ? title
        ? title
        : Object.values(entity!.title_lang_map)[0]
      : "";
  });
}
