<script setup lang="ts">
import { useFrontendStore } from "@/stores/Frontend";
import { useOrganizationsStore } from "@/stores/organization/entity/Organizations";
import { useUserStore } from "@/stores/user/User";
import { ref } from "vue";
import { useRoute, useRouter } from "vue-router";
import Gravatar from "../elements/Gravatar.vue";
import IconLogout from "../icons/general/actions/IconLogout.vue";
import IconMenuToggle from "../icons/general/actions/IconMenuToggle.vue";
import IconUser from "../icons/general/utils/IconUser.vue";
import ThemeSelector from "./ThemeSelector.vue";

const emit = defineEmits(["menuToggled"]);

const frontend = useFrontendStore();
const userStore = useUserStore();
const router = useRouter();
const route = useRoute();

const menuVisible = ref(false);
const organizations = await useOrganizationsStore().getAll();

async function logout() {
  hideMenu();
  router.push({ name: "logout" });
}

function toggleMenu() {
  if (frontend.windowWidth >= frontend.deviceBreakpoints.landscapeTablet) {
    return;
  }

  menuVisible.value = !menuVisible.value;

  if (menuVisible.value) {
    document.body.classList.add("body-dialog-opened");
  } else {
    document.body.classList.remove("body-dialog-opened");
  }

  emit("menuToggled", menuVisible.value);
}

function hideMenu() {
  if (!menuVisible.value) {
    return;
  }

  menuVisible.value = false;
  document.body.classList.remove("body-dialog-opened");
  emit("menuToggled", menuVisible.value);
}
</script>

<template>
  <div v-click-outside="hideMenu">
    <a
      class="toggle-menu"
      :class="{ close: menuVisible }"
      href="#"
      @click.prevent="toggleMenu()"
    >
      <IconMenuToggle :open="menuVisible" />
    </a>

    <div class="mobile-menu" :class="{ 'mobile-menu--opened': menuVisible }">
      <ul class="user-actions">
        <li>
          <RouterLink
            class="unstyled"
            :to="{ name: 'myProfile' }"
            @click="hideMenu()"
          >
            <IconUser :width="18" :height="18" :stroke-width="3" />
            <span> {{ frontend.trans("general.operation.my_profile") }} </span>
            <Gravatar
              class="profile-picture"
              :email="userStore.loggedUserInfo.email"
              defaultImg="@/assets/favicon.png"
            />
          </RouterLink>
        </li>
        <li>
          <RouterLink
            class="unstyled"
            :to="{ name: 'userApiTokens' }"
            @click="hideMenu()"
          >
            <IconUser :width="18" :height="18" :stroke-width="3" />
            {{ frontend.trans("user_api_token.title.list") }}
          </RouterLink>
        </li>
        <li>
          <a href="#" class="unstyled" @click.prevent="logout()">
            <IconLogout :width="18" :height="18" :strokeWidth="2" />
            {{ frontend.trans("general.operation.logout") }}
          </a>
        </li>
      </ul>

      <div class="appearance">
        {{ frontend.trans("general.content.appearance") }}
        <ThemeSelector
          background="var(--enlivy-grey-15-color)"
          :useLabel="false"
        />
      </div>

      <ul class="header-actions">
        <li v-if="organizations.length > 1">
          <RouterLink
            class="unstyled"
            :to="{ name: 'accountOrganizationsDashboard' }"
            @click="hideMenu()"
          >
            {{ frontend.trans("general.operation.account_organizations_dashboard") }}
          </RouterLink>
        </li>
        <li
          v-if="userStore.loggedUserInfo.email_verified_at != null 
          && route.name != 'createOrganization'"
        >
          <RouterLink
            class="unstyled"
            :to="{ name: 'organizations' }"
            @click="hideMenu()"
          >
            {{ frontend.trans("general.operation.organizations") }}
          </RouterLink>
        </li>
        <li
          v-if="userStore.loggedUserInfo.capabilities.includes('administrator')"
        >
          <RouterLink
            class="unstyled"
            :to="{ name: 'administrationDashboard' }"
            @click="hideMenu()"
          >
            {{ frontend.trans("general.operation.administration") }}
          </RouterLink>
        </li>
      </ul>
    </div>
  </div>
</template>

<style scoped lang="scss">
.toggle-menu {
  width: 35px;
  height: 35px;
  border-radius: 50%;
  background: var(--enlivy-primary-dark-color);
  display: flex;
  justify-content: center;
  align-items: center;
}

.mobile-menu {
  position: fixed;
  display: flex;
  width: 100%;
  top: calc(26px + 2 * var( --enlivy-spacing-divider-sm));
  bottom: 0;
  left: 0;
  padding: var( --enlivy-spacing-divider-sm);
  background: var(--enlivy-white-100-color);
  display: flex;
  flex-direction: column;
  gap: 60px;
  overflow-y: scroll;
  opacity: 0;
  visibility: hidden;
  @include transition(0.1s ease-in-out);
  &--opened {
    opacity: 1;
    visibility: visible;
  }

  .user-actions {
    display: flex;
    flex-direction: column;
    gap: var(--enlivy-spacing-divider-md);
    li {
      display: flex;
      align-items: center;
      justify-content: space-between;
      a {
        display: flex;
        align-items: center;
        gap: var(--enlivy-spacing-lg);
        width: 100%;
        span {
          flex: 1;
        }
        .profile-picture {
          width: 35px;
          height: 35px;
          border-radius: 50%;
          overflow: hidden;
        }
      }
    }
  }

  .appearance {
    display: flex;
    justify-content: space-between;
    align-items: center;
    @include font-large;
  }

  .header-actions {
    display: flex;
    flex-direction: column;
    gap: var(--enlivy-spacing-divider-md);
    a {
      @include font-title;
    }
  }
}
</style>
