import type { SelectOption } from "@/interfaces/common/SelectOption";
import { useFrontendStore } from "@/stores/Frontend";

export function useLayoutOptions() {
  const frontend = useFrontendStore();

  return [
    {
      id: "default",
      label: frontend.trans("general.layout.default")!,
      size: "initial",
    },
    {
      id: "compact",
      label: frontend.trans("general.layout.compact")!,
      size: "1200px",
    },
    {
      id: "wide",
      label: frontend.trans("general.layout.wide")!,
      size: "2000px",
    },
  ] as SelectOption[];
}
