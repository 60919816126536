import { unref } from "vue";
import useEventListener from "./EventListener";

export default function useClickAway(
  target: any,
  handler: (event: any) => void
) {
  const event = "pointerdown";

  if (typeof window === "undefined" || !window) {
    return;
  }

  const listener = (event: any) => {
    for (const eventEl of event.composedPath()) {
      if (
        eventEl.classList &&
        eventEl.classList.contains("vs__dropdown-menu")
      ) {
        return;
      }
    }

    const el = unref(target);
    if (!el) {
      return;
    }

    if (el === event.target || event.composedPath().includes(el)) {
      return;
    }

    handler(event);
  };

  return useEventListener(window, event, listener);
}
