import { getDateFormatFromId } from "@/composables/frontend/DateFormatsOptions";
import { useFrontendStore } from "@/stores/Frontend";
import { useUserStore } from "@/stores/user/User";
import { formatInTimeZone } from "date-fns-tz";
import { deepClone } from "../Object";

export const dateToApiFormat = (date: Date | string) => {
  if (date) {
    if (typeof date == "string") {
      date = new Date(date);
    }

    return `${date.toISOString().substring(0, 19)}Z`;
  }

  return undefined;
};

export const getClientEndOfDayIso = (date: Date | string) => {
  if (date) {
    if (typeof date == "string") {
      date = new Date(date);
    }

    const endOfDay = new Date(date);
    endOfDay.setHours(23, 59, 59, 999);

    return dateToApiFormat(endOfDay);
  }

  return undefined;
};

export function getToday() {
  const now = new Date();
  return new Date(now.getFullYear(), now.getMonth(), now.getDate());
}

export function daysUntilDate(untilDate: string | Date) {
  const todayDate = getToday();
  const givenDate = new Date(untilDate);

  const timeDifferenceInMilliseconds =
    givenDate.getTime() - todayDate.getTime();
  const timeDifferenceInDays =
    timeDifferenceInMilliseconds / (1000 * 60 * 60 * 24);

  return timeDifferenceInDays;
}

export const convertToUTC = (date: Date) => {
  return date.toISOString();
};

export const dateToDisplayFormat = (date: Date | string | undefined) => {
  if (date) {
    if (typeof date == "string") {
      date = new Date(date);
    }

    const frontend = useFrontendStore();
    const userStore = useUserStore();
    const dateSettings = frontend.dateTimeSettings;
    const dateFormat = getDateFormatFromId(dateSettings.dateFormat);
    const timeZone = () => {
      if (dateSettings.timeZone === "local") {
        return Intl.DateTimeFormat().resolvedOptions().timeZone;
      } else if (dateSettings.timeZone === "organization") {
        return userStore.activeOrganization?.timezone;
      }

      return (
        dateSettings.timeZone ||
        Intl.DateTimeFormat().resolvedOptions().timeZone
      );
    };

    if (dateSettings.dateFormat === "date_format_option_iso_8601") {
      return `${formatInTimeZone(date, timeZone()!, dateFormat!).replace(" ", "T")}Z`;
    } else {
      if (dateSettings.dateFormat === "local" || !dateFormat) {
        return formatInTimeZone(date, timeZone()!, "Pp");
      }
      return formatInTimeZone(date, timeZone()!, dateFormat!);
    }
  }

  return undefined;
};

export function apiDateToJsObject(date: string) {
  return new Date(date);
}

// Update the interval with new start and end dates.
// If the start date & end date end up reversed, adjust with one month difference.
export function updateInterval(
  interval: { startDate?: Date; endDate?: Date },
  newStartDate?: Date,
  newEndDate?: Date
) {
  const newInterval = deepClone(interval);

  if (newStartDate) {
    newInterval.startDate = newStartDate;
    if (newInterval.endDate && newStartDate >= newInterval.endDate) {
      newInterval.endDate = new Date(newStartDate);
      newInterval.endDate.setMonth(newStartDate.getMonth() + 1);
    }
  }
  if (newEndDate) {
    newInterval.endDate = newEndDate;
    if (newInterval.startDate && newEndDate <= newInterval.startDate) {
      newInterval.startDate = new Date(newEndDate);
      newInterval.startDate.setMonth(newEndDate.getMonth() - 1);
    }
  }

  return newInterval;
}

export const RelativeDateIntervals = {
  "current-month": (date: Date) => ({
    startDate: new Date(date.getFullYear(), date.getMonth(), 1),
    endDate: new Date(date.getFullYear(), date.getMonth() + 1, 0),
  }),
  "previous-month": (date: Date) => ({
    startDate: new Date(date.getFullYear(), date.getMonth() - 1, 1),
    endDate: new Date(date.getFullYear(), date.getMonth(), 0),
  }),
  "last-3-months": (date: Date) => ({
    startDate: new Date(date.getFullYear(), date.getMonth() - 2, 1),
    endDate: new Date(date.getFullYear(), date.getMonth() + 1, 0),
  }),
  "last-6-months": (date: Date) => ({
    startDate: new Date(date.getFullYear(), date.getMonth() - 5, 1),
    endDate: new Date(date.getFullYear(), date.getMonth() + 1, 0),
  }),
  "last-12-months": (date: Date) => ({
    startDate: new Date(date.getFullYear(), date.getMonth() - 11, 1),
    endDate: new Date(date.getFullYear(), date.getMonth() + 1, 0),
  }),
  "current-year": (date: Date) => ({
    startDate: new Date(date.getFullYear(), 0, 1),
    endDate: new Date(date.getFullYear() + 1, 0, 0),
  }),
  "previous-year": (date: Date) => ({
    startDate: new Date(date.getFullYear() - 1, 0, 1),
    endDate: new Date(date.getFullYear(), 0, 0),
  }),
  monthly: (date: Date) => ({
    startDate: new Date(date.getFullYear(), date.getMonth(), 1),
    endDate: new Date(date.getFullYear(), date.getMonth() + 1, 0),
  }),
  yearly: (date: Date) => ({
    startDate: new Date(date.getFullYear(), 0, 1),
    endDate: new Date(date.getFullYear() + 1, 0, 0),
  }),
  custom: (date: Date) => ({
    startDate: new Date(date.getFullYear(), date.getMonth(), 1),
    endDate: new Date(date.getFullYear(), date.getMonth() + 1, 0),
  }),
};

export type RelativeDateInterval = keyof typeof RelativeDateIntervals;
export function getRelativeDateInterval(name: RelativeDateInterval) {
  return RelativeDateIntervals[name](new Date());
}

export function getDateFromDashString(monthDate: string) {
  const [year, month] = monthDate.split("-");
  return new Date(parseInt(year), parseInt(month), 0);
}
