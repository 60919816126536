<script setup lang="ts">
import Input from "@/components/forms/fields/Input.vue";
import LoaderMiniSpinnerTimed from "@/components/loaders/LoaderMiniSpinnerTimed.vue";
import { standardLoadingDelay } from "@/lib/GlobalVariables";
import { useFrontendStore } from "@/stores/Frontend";
import { ref } from "vue";

const emit = defineEmits(["changeSearchQuery", "isFocused", "clearSearch", "loading"]);
const props = withDefaults(
  defineProps<{
    searchQuery?: string;
    displayIcon?: boolean;
    displayFullWidth?: boolean;
    autocomplete?: string;
    placeholder?: string;
    fetchOnSearch?: boolean;
  }>(),
  {
    displayIcon: false,
    displayFullWidth: false,
    placeholder: '',
    fetchOnSearch: true,
  },
);

const frontend = useFrontendStore();
const searchLoader = ref();
const isFocus = ref(false);

let delayTimer: any;
const search = (newValue: string) => {
  if (!newValue) {
    emit("changeSearchQuery", "");
    emit("loading", false);
    return;
  }

  clearTimeout(delayTimer);
  if (newValue == props.searchQuery) {
    searchLoader.value.stopAnimation();
    emit("loading", false);
  } else {
    if (props.fetchOnSearch) {
      searchLoader.value.resetAnimation();
      emit("loading", true);
      delayTimer = setTimeout(function () {
        emit("changeSearchQuery", newValue);
        emit("loading", false);
      }, standardLoadingDelay);
    } else {
      emit("changeSearchQuery", newValue);
    }
  }
}

const clearSearch = () => {
  if (props.searchQuery && !searchLoader.value?.loading) {
    emit("clearSearch");
  }
}

const inputFocusToggle = (val: boolean) => {
  emit("isFocused", val);

  if (val) {
    isFocus.value = true;
  } else {
    isFocus.value = false;
  }
}
</script>

<template>
  <div
    class="search"
    :class="{
      'display-icon': displayIcon,
      'display-full-width': displayIcon,
      'has-value': searchQuery,
    }"
  >
    <div
      class="search-icon"
      :class="{
        'clear-search': searchQuery,
      }"
      @click="clearSearch"
      v-if="displayIcon"
    ></div>

    <Input
      id="search"
      :modelValue="searchQuery"
      :placeholder="placeholder ? placeholder : frontend.trans('general.operation.search')"
      :autocomplete="autocomplete"
      class="white"
      @update:modelValue="search"
      @isFocused="inputFocusToggle"
    />

    <LoaderMiniSpinnerTimed
      v-if="fetchOnSearch"
      ref="searchLoader"
      class="search-loader"
    />
  </div>
</template>

<style scoped lang="scss">
.search {
  position: relative;
  display: flex;
  align-items: center;

  .search-icon {
    position: absolute;
    left: var(--enlivy-spacing-divider-xs);
    z-index: 1;
    width: 24px;
    height: 24px;
    @include transition(all 0.3s ease);
    &:before,
    &:after {
      content: "";
      position: absolute;
    }
    &:before {
      width: 14px;
      height: 14px;
      border: 2px solid var(--enlivy-grey-75-color);
      border-radius: 50%;
      top: 4px;
      left: 4px;
    }
    &:after {
      width: 2px;
      height: 8px;
      background-color: var(--enlivy-grey-75-color);
      top: 14px;
      left: 17px;
      transform: rotate(-45deg);
    }
  }

  &.has-value {
    .search-icon {
      cursor: pointer;
      &:before {
        border: none;
        border-radius: 0;
        transform: rotate(45deg);
        left: 17px;
      }
      &:after,
      &:before {
        top: 4px;
        left: 14px;
        width: 2px;
        height: 18px;
        background-color: var(--enlivy-grey-75-color);
      }
      &:hover {
        &:before,
        &:after {
          background-color: var(--enlivy-red-100-color);
        }
      }
    }
  }

  &.display-icon:deep(input) {
    padding-left: var(--enlivy-spacing-divider-lg);
  }

  &.display-full-width {
    width: 100%;
    .form-field {
      width: 100%;
    }
  }

  .search-loader {
    position: absolute;
    right: var(--enlivy-spacing-divider-sm);
  }
}
</style>
