import { handleApiError } from "@/api/generic/ApiErrorHandler";
import { OrganizationGenericEntityApi } from "@/api/generic/OrganizationGenericEntityApi";
import { ApiResponseParser } from "@/api/mapping/ApiResponseParser";
import { authHeader } from "@/composables/api/RequestHeaderGenerator";
import type { Navigation } from "@/interfaces/common/Navigation";
import type { NavigationByState } from "@/interfaces/common/NavigationByState";
import type { Pagination } from "@/interfaces/common/Pagination";
import type { EntityIdentifier } from "@/interfaces/generic/EntityIdentifier";
import type { OrganizationInvoiceNetworkExchange } from "@/interfaces/organization/invoice/OrganizationInvoiceNetworkExchange";
import type { OrganizationInvoiceNetworkExchangeApiInformation } from "@/interfaces/organization/invoice/OrganizationInvoiceNetworkExchangeApiInformation";
import axios from "axios";

class OrganizationInvoiceNetworkExchangeApi extends OrganizationGenericEntityApi<OrganizationInvoiceNetworkExchange> {
  apiIdentifier = "organization_invoice_network_exchange";

  getBaseUrl(args: { organizationId: string }) {
    return `/organizations/${args.organizationId}/invoices/network-exchanges`;
  }

  parseEntity(entityFromApi: any) {
    const entity = new ApiResponseParser(
      entityFromApi,
      "organization_invoice_network_exchange"
    )
      .asOrganizationEntity()
      .asId("organization_invoice_id")
      .asId("institution_id")
      .asId("institution_exchange_id")
      .asId("institution_exchange_message_id")
      .asDate("institution_exchange_message_created_at")
      .asObject("response_json")
      .build() as OrganizationInvoiceNetworkExchange;

    return entity;
  }

  getDefaultIncludeMeta() {
    return [];
  }

  async getNetworkExchangeInformation(args: {
    entity: OrganizationInvoiceNetworkExchange;
    criticalFetch?: boolean;
    allowMissing?: boolean;
  }) {
    const url =
      this.getBaseUrl({ organizationId: args.entity.organization_id }) +
      `/${args.entity.id}/information`;

    try {
      const response = await axios.get(url, {
        headers: authHeader(),
      });

      return response.data
        .data as OrganizationInvoiceNetworkExchangeApiInformation;
    } catch (error) {
      handleApiError(error, args.criticalFetch, args.allowMissing);
    }
  }

  async getNetworkExchangePull(args: {
    organization_id: string;
    institution: string;
    date_from: Date;
  }) {
    const url =
      this.getBaseUrl({ organizationId: args.organization_id }) +
      `/${args.institution}/pull`;

    try {
      const response = await axios.get(url, {
        headers: authHeader(),
        params: { date_from: args.date_from },
      });

      return response.data;
    } catch (error) {
      handleApiError(error);
    }
  }

  async downloadSignature(args: {
    entity: OrganizationInvoiceNetworkExchange;
    params?: any;
  }) {
    const downloadUrl =
      this.getBaseUrl({ organizationId: args.entity.organization_id }) +
      `/${args.entity.id}/download-signature`;

    try {
      const headers = authHeader();

      const response = await axios.get(downloadUrl, {
        params: args.params,
        headers,
        responseType: "arraybuffer",
      });

      return response;
    } catch (error) {
      handleApiError(error);
    }
  }

  async downloadPDF(args: {
    entity: OrganizationInvoiceNetworkExchange;
    params?: any;
  }) {
    const downloadUrl =
      this.getBaseUrl({ organizationId: args.entity.organization_id }) +
      `/${args.entity.id}/download-pdf`;

    try {
      const headers = authHeader();

      const response = await axios.get(downloadUrl, {
        params: args.params,
        headers,
        responseType: "arraybuffer",
      });

      return response;
    } catch (error) {
      handleApiError(error);
    }
  }

  async get(args: {
    entityIdentifier: EntityIdentifier;
    page?: number;
    limit?: number;
    deleted?: number;
    params?: { [key: string]: any };
  }): Promise<{
    entities: OrganizationInvoiceNetworkExchange[];
    pagination: Pagination;
    navigation: Navigation;
    navigationByState: NavigationByState;
  }> {
    return super.get({
      ...args,
      params: {
        ...args.params,
        ...(args.entityIdentifier.invoiceId && {
          organization_invoice_id: args.entityIdentifier.invoiceId,
        }),
      },
    });
  }
}

export function useOrganizationInvoiceNetworkExchangeApi() {
  return new OrganizationInvoiceNetworkExchangeApi();
}
